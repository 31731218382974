import { progressData } from '../utils/data';
import React from 'react';
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import { useStateContext } from 'Context/ContextProvider';
const CustomerSatisfation = () => {
  const {tickets} = useStateContext()
  const response = tickets.filter(data=>data.rating)
  const positive = tickets.filter(data=>data.rating===4 ||data.rating===5)
  const nuetral = tickets.filter(data=>data.rating===3)
  const negative = tickets.filter(data=>data.rating===1 || data.rating===2)
  const progressData = [
    { id: 1, color: "success", label: "Positive", percentage: Math.round((positive.length/response.length)*100 )},
    { id: 2, color: "warning", label: "Nuetral", percentage: Math.round((nuetral.length/response.length)*100) },
    { id: 3, color: "danger", label: "Negative", percentage: Math.round((negative.length/response.length)*100) }
];
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="ms-2">
                <h5 className="card-title mb-3">Customer Satisfaction</h5>
              </div>
            </div>
            <Row className="text-center">
              <Col xs={12}>
              
                <div className="mt-3">
                <div className="avatar-md  mx-auto mb-3">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-24 display-6 p-3">
                        <i className="bx bx-conversation"></i>
                      </div>
                    </div>
                  <p className="text-muted mb-1">Response recieved</p>
                  <h5>{response.length}</h5>
                </div>
              </Col>
            </Row>

            {/* <hr /> */}

            <div className='mb-2'>
              <ul className="list-group list-group-flush">
                {(progressData || []).map((item, index) => (
                  <li className="list-group-item" key={index}>
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {item.label} <span className="float-end">{item.percentage}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className={`progress-bar bg-${item.color}`}
                          role="progressbar"
                          style={{ width: `${item.percentage}%` }}
                          aria-valuenow={item.percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default CustomerSatisfation;