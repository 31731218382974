import React from 'react'
import { Card,Modal,CardBody,CardTitle, Button,Input, Row, Col,Form, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { tog_backdrop,addTask } from 'pages/Ticket/features/modalSlice';
import { DatePicker } from 'antd';
import * as Yup from "yup";
import { Field, Formik, useFormik } from "formik";
import Cookies from 'js-cookie';
import { todoData } from 'pages/Ticket/utils/todoData';

const ToDoModal = () => {
    const isOpen = useSelector((state)=>state.modal.isOpen)
    const dispatch = useDispatch()
    const initialValues={
      date:"",
      subject:"",
      description:"",
      name:Cookies.get('name'),
      id:todoData.length,
    }
    const schema = Yup.object().shape({
      date: Yup.string()
        .required('Please select task date'),
      subject: Yup.string().min(3).required("Please Enter Your Subject"),
      description: Yup.string().min(3).required("Please Enter Your reason"),
    });
    const { values, setFieldValue,resetForm, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
      initialValues: initialValues,
      validationSchema: schema,
      onSubmit: (values) => {
        dispatch(addTask(values))
        resetForm()
        dispatch(tog_backdrop())
      }
    })
  return (
    <>
    <Modal isOpen={isOpen}  toggle={() => {
      dispatch(tog_backdrop())
    }} backdrop={'static'}
    id="staticBackdrop"
    >
        <div className="modal-header">
                        <h6 className="modal-title text-primary" id="staticBackdropLabel"><i className='bx bx-task' /> Add Task!</h6>
                        <button type="button ms-2" className="btn-close"
                          onClick={() => {
                            dispatch(tog_backdrop())
                          }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                       
                     <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={"12"} className='mb-2' >
                          <Label>Task Date</Label>
                        <DatePicker
                                className={errors.date ? "  border-danger form-control" : "form-control"}
                                id="formrow-email-Input"
                                style={{fontFamily:'inherit'}}
                                placeholder="Enter task date..."
                                onChange={(date, string) => values.date = string}
                                onBlur={handleBlur}
                                format='YYYY-MM-DD'
                              />
                              {errors.date && <small className="text-danger m-0">{errors.date}</small>}
                        </Col>
                        <Col lg={"12"} className='mb-2'>
                        <Label>Subject</Label>
                        <Input
                                className={errors.subject ? "  border-danger form-control" : "form-control"}
                                id="formrow-email-Input"
                                name='subject'
                                style={{fontFamily:'inherit'}}
                                placeholder="Enter the subject..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.subject}
                              />
                              {errors.subject && <small className="text-danger m-0">{errors.subject}</small>}
                        </Col>
                        <Col lg={"12"} className='mb-2'>
                        <Label>Description</Label>
                        <Input
                                className={errors.description ? "  border-danger form-control" : "form-control"}
                                id="formrow-email-Input"
                                name='description'
                                style={{fontFamily:'inherit'}}
                                placeholder="Enter the subject..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                              />
                              {errors.description && <small className="text-danger m-0">{errors.description}</small>}
                        </Col>
                      </Row>
                      <div className='float-end modal-footer d-flex gap-2'>
                        <button type='submit' className='btn bg-primary text-white'>+ Add</button>
                        <button color='light' className='btn bg-light ' onClick={()=>dispatch(tog_backdrop())}>Cancel</button>
                        </div> 
                     </Form>
                      </div>
                      
    </Modal>
    </>
  )
}

export default ToDoModal