import { DatePicker } from 'antd'
import React, { useState, useEffect } from 'react'
import { Form, Input, Label, Modal, ModalBody, Row, Col } from 'reactstrap'
import { db } from 'firebase-config';
import { addDoc, collection, doc, getDocs, query, where } from 'firebase/firestore';

const TaskForm = ({isOpen,setIsOpen,data}) => {
    const [consultant,setConsultants] = useState([])
    useEffect(() => {
        const getConsultants = async () => {
          const colRef = query(collection(db, "users"), where("team", '==', "Delivery"))
          const data = await getDocs(colRef).catch((err) => console.log(err))
          setConsultants(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getConsultants()
      }, [])
    const [form,setForm] = useState({
        taskTitle:"",
        taskDescription:"",
        assignedTo:"",
        startDate:"",
        endDate:"",
        hours:"",
        status:"Open"
    })
    const handleChange=(e)=>{
        setForm({...form, [e.target.name]:e.target.value})
    }
    const handleSubmit=async()=>{
        await addDoc(collection(db,"Tickets",data.id,"tasks"),form).then(()=>setIsOpen(!isOpen)).catch((err)=>console.log(err))

    }
    
  return (
    <Modal isOpen={isOpen} backdrop={"static"} >
        <div className='modal-header'>
            <h6 className='d-flex align-items-center gap-1 '><i className='bx bx-task font-size-16' />Create Task</h6>
            <button type="button ms-2" className="btn-close"
                          onClick={() => {
                            setIsOpen(!isOpen)
                          }} aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <Form onSubmit={(e)=>e.preventDefault()}>
                <Row>
                    <Col sm={"12"} className='mb-3'>
                    <Label>Task title</Label>
                    <Input name='taskTitle' type='text' className='for-control' value={form.taskTitle} onChange={handleChange} placeholder='Task Title' />
                    </Col>
                   <Col sm={"12"} className='mb-3'>
                   <Label>Task description</Label>
                    <Input name='taskDescription' type='textarea' className='for-control' value={form.taskDescription} onChange={handleChange} placeholder='Task description' />
                   </Col>
                   <Col sm={"12"} className='mb-3'>
                    <Label>Assigned to</Label>
                    <select className='form-control' name='assignedTo' onChange={handleChange}>
                        <option value={"#"} className='text-muted'>Assign to</option>
                        {
                            consultant.map((con,index)=>(
                                <option value={con.email} key={index}>{con.name}</option>
                            ))
                        }
                    </select>
                    </Col>
                   <Col sm={"6"} className='mb-3'>
                   <Label>Start Date</Label>
                    <DatePicker name='startDate' className='form-control' style={{fontFamily:"inherit"}}  onChange={(date, string) => setForm({...form,startDate:string})} />
                   </Col>
                   <Col sm={"6"} className='mb-3'>
                   <Label>End Date</Label>
                   <DatePicker name='endDate' className='form-control' style={{fontFamily:"inherit"}} onChange={(date, string) => setForm({...form,endDate:string})} />
                   </Col>
                   <Col sm={"12"} className='mb-3'>
                   <Label>Hours</Label>
                   <Input type='number' name='hours' className='for-control' value={form.hours} onChange={handleChange} placeholder='Enter the planned hours' />
                   </Col>   
                </Row>
                <div className='d-flex float-start gap-2'>
                        <button type="submit" className=' btn bg-primary text-white' onClick={()=>handleSubmit()}>
                         Assign task
                        </button>
                        <button type="button" className=' btn bg-light text-dark' onClick={() => setIsOpen(!isOpen)}>
                          Cancel
                        </button>
                      </div>
            </Form>
        </div>
    </Modal>
  )
}

export default TaskForm