import React,{useEffect,useState} from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useStateContext } from 'Context/ContextProvider';

const JobWidgetCharts = ({ dataColors, series }) => {
    var areacharteathereumColors = getChartColorsArray(dataColors);

    var options = {
        chart: {
            width: 130,
            height: 46,
            type: "area",
            sparkline: {
                enabled: true,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 1.5,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
            },
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        },
        colors: areacharteathereumColors,
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height="46"
                width="130"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const StatisticsApplicationsChart = ({ seriesData, dataColors }) => {
    const lineColumnAreaColors = getChartColorsArray(dataColors);
    const {tickets} = useStateContext()
    const openTickets = tickets.filter((data)=>data.status==="Open")
    const overallDataPerMonth=[]
    const openDPM=[]
    const [overall,setOverAll]= useState([])
    const [open,setOpen] = useState([])
    useEffect(()=>{
      const getData=()=>{
        const month = [1,2,3,4,5,6,7,8,9,10,11,12]
        month.map((data,index)=>{
          let count1=0;
          let count2=0;
          tickets.map((data)=>{
            if(new Date(data.ticketDate).getMonth()==index){
              count1++
            }
          })
          openTickets.map((data)=>{
            if(new Date(data.ticketDate).getMonth()==index){
              count2++
            }
          })
          overallDataPerMonth.push(count1)
          openDPM.push(count2)
        })
   setOpen(openDPM)
   setOverAll(overallDataPerMonth)
      }
      getData()
    },[])
   
    const apaexlineChartColors = getChartColorsArray(dataColors);

    const series = [
      { name: "Overall", data: [...overall] },
      { name: "Open", data:[ ...open ] },
    ]
    const options = {
      chart: {
        height: 380,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: apaexlineChartColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3],
        curve: 'smooth'
      },
      series: [{
        name: "Overall",
        data:[ ...overall]
      },
      {
        name: "Open",
        data: [...open]
      }
      ],
      title: {
        text: '',
        align: 'left',
        style: {
          fontWeight: '500',
        },
      },
      grid: {
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.2
        },
        borderColor: '#f1f1f1'
      },
      markers: {
        style: 'inverted',
        size: 6
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',"Aug","Sep","Oct","Nov","Dec"],
        title: {
          text: 'Month'
        }
      },
      yaxis: {
        title: {
          text: 'Tickets worked'
        },
        min: 0,
        max: 10
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      },
      responsive: [{
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          legend: {
            show: false
          },
        }
      }]
    }
    return (
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="380"
        className="apex-charts"
      />
    )
};

const ReceivedTimeCharts = ({ dataColors }) => {
    var ApplicationReveicedTimeColors = getChartColorsArray(dataColors);

    const series = [{
        name: 'Received Application',
        data: [34, 44, 54, 21, 12, 43, 33, 80, 66]
    }];
    var options = {
        chart: {
            type: 'line',
            height: 378,
            toolbar: {
                show: false,
            },
        },
        // stroke: {
        //     curve: 'stepline',
        // },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        labels: ['8 PM', '9 PM', '10 PM', '11 PM', '12 PM', '1 AM', '2 AM',
            '3 AM', '4 AM'
        ],
        dataLabels: {
            enabled: false
        },
        colors: ApplicationReveicedTimeColors,
        markers: {
            hover: {
                sizeOffset: 4
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="393px"
                // width="456px"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export { JobWidgetCharts, StatisticsApplicationsChart, ReceivedTimeCharts };