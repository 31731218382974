import React, {useState} from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Rating } from "react-simple-star-rating";
const RatingModal = ({open,toggle,handleUpdate,setRating}) => {
  const handleRating = (rate) => {
    setRating(rate)
  }
  return (
    <Modal isOpen={open} toggle={toggle}>
      <div className="modal-header">
                        <h5 className="modal-title text-success" id="staticBackdropLabel">Customer Satisfaction</h5>
                        <button type="button" className="btn-close"
                          onClick={() => {
                           toggle()
                          }} aria-label="Close"></button>
                      </div>
        <ModalBody className='d-flex flex-column justify-content-center align-items-center'>
          
            <p>Rate the customer satisfaction before closing the ticket!</p>
            <Rating size={25}  onClick={handleRating} />
        </ModalBody>
        <div className="modal-footer">
                        <button type="button" className="btn btn-light" onClick={() => {
                          handleUpdate("Closed");
                        }}>Close ticket</button>
                      </div>
    </Modal>
  )
}

export default RatingModal