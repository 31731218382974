import React, { useEffect, useState } from 'react'
import { useStateContext } from 'Context/ContextProvider'
import { db } from "firebase-config";
import {Card, CardBody, Row, Col, Badge,DropdownMenu,DropdownToggle,UncontrolledDropdown,Button } from "reactstrap"
import { collection, getDocs, onSnapshot, query, where, updateDoc, doc, orderBy } from "firebase/firestore";
import Cookies from 'js-cookie';
import TicketCard from './components/TicketCard';
import noDataFound from "assets/images/nodatafound.png"

const MyTask = () => {
    const {tasks,setTasks} = useStateContext()
    const [taskArr,setTaskArr] = useState([])
    const handleUpdate=async(status,id,dataId)=>{
        await updateDoc(doc(db,"Tickets",dataId,"tasks",id),{status:status}).catch(err=>console.log(err))
    }
    useEffect(()=>{
        const getData=()=>{
            const arr=[]
            const collRef = query(collection(db,"Tickets"))
            onSnapshot(collRef,(data)=>{
              console.log(data.docs.map((doc)=>doc.data()))
              data.docs.map(async(doc)=>{
                const filteredQuery = query(collection(db,"Tickets",doc.id,"tasks"))
              const taskData=  await getDocs(filteredQuery)
              taskData.docs.map((doc)=> console.log(doc.data()))
              const filteredTaskData = taskData.docs.map((doc1)=>({...doc1.data(),id:doc1.id,customNo:doc.data().customNo}))
              if(filteredTaskData.length>0){
               setTaskArr([
                {...doc.data(),id:doc.id}])
              }
              taskData.docs.map((doc1)=>(arr.push({...doc1.data(),id:doc1.id,customNo:doc.data().customNo,dataId:doc.id})))
            })
            })
            setTasks(arr)
        }
        getData()
    },[])
   console.log(tasks)
  return (
    <React.Fragment>
    <Row className="page-content">
    <Col className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-task pb-3 ms-2 mt-2 fs-2 pe-2"></i>
          <h4 className="font-size-16">Team Tasks</h4>
        </div>
      </Col>
      {
       tasks.length>0 ?  tasks.map((data,index)=>(
        <Card key={index}>
        <CardBody>
        <Row>
            <Col>Ticket</Col>
                    <Col>Task</Col>
                    <Col>Status</Col>
                    <Col>Due date</Col>
                    <Col lg={2}>Assigned to</Col>
                    <Col>Planned hours</Col>
                    <Col lg={2}>Description</Col>
                </Row>
                        <Row className='mt-3 mb-3 text-start' key={index}>
                            <Col>{data.customNo}</Col>
                            <Col >{data.taskTitle}</Col>
                            <Col>
                            {
                                    Cookies.get("email") === data.assignedTo ? (
                            <UncontrolledDropdown>
                                <DropdownToggle  className='text-dark'
                            tag="a"
                            color="white">
                                {data.status ==="Open" ?(<div className=' d-flex align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):((data.status ==="In-progress" ? (<div className='d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):(data.status ==="Closed") && (<div className='d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>{data.status}</div>)))}
                                </DropdownToggle>
                                
                                        <DropdownMenu className="dropdown-menu-end">
                                    <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Open",data.id,data.dataId)}>
                                    <div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>
                                            Open
                                        </Button>
                                        <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("In-progress",data.id,data.dataId)}>
                                         <div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>
                                            In-progress
                                        </Button>
                                        <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Closed",data.id,data.dataId)}>
                                    <div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>
                                            Closed
                                        </Button>
                                </DropdownMenu> 
                            </UncontrolledDropdown>
                             ):(data.status ==="Open" ?(<div className=' d-flex align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):((data.status ==="In-progress" ? (<div className=' d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):(data.status ==="Closed") && (<div className=' d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>{data.status}</div>))) )
                            }
                            </Col>
                            <Col>{data.endDate.split("-").reverse().join("-")}</Col>
                            <Col lg={2}>{data.assignedTo}</Col>
                            <Col>{data.hours}</Col>
                            <Col lg={2}>{data.taskDescription}</Col>
                        </Row>
        </CardBody>
       </Card>
        )) : (<div className={'d-flex align-items-center justify-content-center flex-column '}>
        <img src={noDataFound} height={400} width={500} />
        <p>No tasks assigned !</p>
    </div>)
      }
    </Row>
  </React.Fragment>
  )
}

export default MyTask