import { db } from 'firebase-config'
import { query, collection, orderBy, getDocs,  } from 'firebase/firestore'
import React, { useEffect, useState, useMemo } from 'react'
import { Status,Actions,ReverseDate } from '../WFH/WFHRecordsCol'
import TableContainer from '../../components/Common/TableContainer';

const AllRecords = () => {
    const [data,setData] = useState([])
    useEffect(()=>{
        const getData=async()=>{
            const filteredQuery = query(collection(db,"WFH"),orderBy("timestamp","desc"))
            const fetchedData = await getDocs(filteredQuery)
            setData(fetchedData.docs.map((doc)=>({...doc.data(),id:doc.id})))
        }
        getData()
    },[])
    const columns = useMemo(
        () => [
            {
                Header: 'Date of Request',
                accessor: 'requestDate',
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                  return <ReverseDate {...cellProps} />;
                },
            }, 
            {
              Header: 'Name',
              accessor: 'name',
              disableFilters: true,
              filterable: false,
              Cell: cellProps => {
                return <ReverseDate {...cellProps} />;
              },
          },           
            {
                Header: 'Subject',
                accessor: 'subject'
            },       
            {
                Header: 'From',
                accessor: 'from',
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                  return <ReverseDate {...cellProps} />;
                },
            },
            {
                Header: 'To',
                accessor: 'to',
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                  return <ReverseDate {...cellProps} />;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                  return <Status {...cellProps} />;
                },
              },
        ],
        []
    ); 
  return (
    <div className='page-content'>
    <TableContainer
    columns={columns}
    data={data}
    isGlobalFilter={true}
    isAddOptions={false}
    customPageSize={10}
    isPagination={true}
    tableClass="align-middle table-nowrap table-check table"
    theadClass="table-light"
    paginationDiv="col-12"
    pagination="justify-content-center pagination pagination-rounded"
/>
    </div>
  )
}

export default AllRecords