import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Col, Row, Nav,CardText,  NavItem, NavLink, TabContent,TabPane,} from "reactstrap";
import TicketCard from "./components/TicketCard";
import { db } from "firebase-config";
import { collection, getDocs, onSnapshot, query, where, orderBy } from "firebase/firestore";
import Cookies from "js-cookie";
import { functionalModules, technicalModules } from "./utils/data";
import { useStateContext } from "Context/ContextProvider";
import FilterPriority from "./components/FilterPriority";

const RiseTicket = () => {
 const {tickets} = useStateContext()
  const [customActiveTab, setcustomActiveTab] = useState("0");
  const [priority,setPriority] = useState("")
 const [teamTickets,setTeamTickets] = useState([])
 const toggleCustom = tab => {
  if (customActiveTab !== tab) {
    setcustomActiveTab(tab);
  }
};

const nav = ["All","Open","Customer Action","In-progress Functional","In-progress Technical","Closed"]
useEffect(()=>{
const handleFilter=()=>{
  if(customActiveTab!=='0'){
    const filteredData = tickets.filter((data)=>data.status===nav[customActiveTab])
    setTeamTickets(filteredData)
  }
}
handleFilter()
},[customActiveTab,tickets])
useEffect(()=>{
  const handlePriority=()=>{
  if(priority){
    if(customActiveTab!=='0'){
      const data=teamTickets.filter((data)=>data.priority===priority)
      setTeamTickets(data)
    }else{
      const data=tickets.filter((data)=>data.priority===priority)
      setTeamTickets(data)
    }
  }
  }
  handlePriority()
},[priority])
console.log(teamTickets)
  return (
    <React.Fragment>
      <Row className="page-content">
      <Col className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <i className="mdi mdi-ticket pb-3 ms-2 mt-2 fs-2 pe-2"></i>
            <h4 className="font-size-16">View Tickets</h4>
          </div>
          <div className="me-5">
          <FilterPriority setPriority={setPriority} />
          </div>
        </Col>
        <Nav tabs className="nav-tabs-custom nav-justified">
          {
            nav.map((data,index)=>(
              <NavItem key={index}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === index.toString(),
                })}
                onClick={() => {
                  toggleCustom(index.toString());
                  setPriority("")
                }}
              >
                <span >{data}</span>
              </NavLink>
            </NavItem>
            ))
          }         
                  </Nav>
                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted">
                    <TabPane tabId="0">
                      <Row>
                        <Col sm="12">
                        {!priority&&tickets.length>0 ? tickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />)):priority && teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          {teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        {teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                        {teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                        {teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                        {teamTickets.length>0 && teamTickets.map((obj)=>(<TicketCard key={obj.id} data={obj} />))}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
      
      </Row>
    </React.Fragment>
  )
}

export default RiseTicket