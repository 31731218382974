import React from 'react'
import LeaveForm from 'pages/Forms/LeaveForm'

const AddLeave = () => {
  return (
    <div>
        <LeaveForm />
    </div>
  )
}

export default AddLeave