import React from 'react'
import {Card, CardBody, Row, Col, Badge,DropdownMenu,DropdownToggle,UncontrolledDropdown,Button } from "reactstrap"

const FilterPriority = ({setPriority}) => {
  return (
    <UncontrolledDropdown>
    <DropdownToggle  className='text-dark'
tag="a"
color="white">
    <i className='bx bx-filter-alt font-size-16' /> Filter Priority <i className='bx bx-chevron-down font-size-16' />
    </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
        <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setPriority("Urgent")}>
        <div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>
                Urgent
            </Button>
            <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setPriority("High")}>
        <div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>
                High
            </Button>
            <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setPriority("Medium")}>
             <div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>
                Medium
            </Button>
            <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setPriority("Low")}>
        <div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>
               Low
            </Button>
            <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setPriority("")}>
            <i className='fa fas-x-mark' />
               Clear
            </Button>
    </DropdownMenu> 
</UncontrolledDropdown>
  )
}

export default FilterPriority