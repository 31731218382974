import React,{useState,useEffect} from 'react'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Alert,
  } from "reactstrap"
  import { db } from 'firebase-config'
import { doc, addDoc, collection, getDoc, Timestamp, getDocs, updateDoc,query, where} from "firebase/firestore";
import {useNavigate } from 'react-router'
import Cookies from 'js-cookie';
const FormPage = () => {
  const [users,setUsers] = useState([])
  useEffect(()=>{
    const getData=async()=>{
      const collRef = query(collection(db,"users"),where('team',"==","Delivery")) 
      const data = await getDocs(collRef)
      const worked=["0.0","0.0","0.0","0.0","0.0","0.0","0.0","0.0","0.0","0.0","0.0","0.0"]
     setUsers(data.docs.map((doc)=>({name:doc.data().name,worked:worked})))
    }
    getData()
  },[])
  console.log(users)
  const addOptions=(type,item)=>{
    
    addDoc(collection(db, type), {service:item}).then(()=>{
    
       })
}
const addCostCenter=()=>{
 document.getElementById('hrsLimit').style.display='block'
}
const addTotHrs=(item,time)=>{
  const tothrs=[time,time,time,time,time,time,time,time,time,time,time,time]
    addDoc(collection(db, 'costCenter'), {service:item,members:users,tothrs:tothrs,workedHrs:["0","0","0","0","0","0","0","0","0","0","0","0"]}).then(()=>{
        document.getElementById('hrsLimit').style.display='none'
      })
}

  const email=Cookies.get('email');
  const team=Cookies.get('team');
  const nav = useNavigate()
  const [projectName,setProjectName]=useState('');
    const [serviceName,setServiceName]=useState('');
    const [costCenter,setCostCenter]=useState('');
    const [workItem,setWorkItem]=useState('');
    const [ID,setID]=useState('');
    const[hrs,setHrs]=useState([])
    const [alert,setAlert]=useState('d-none')
    let dangerAlert='d-none'
const timestamp = Timestamp.now()

 const debouncedClickHandler=(ele)=>{setID(ele.id)};
 const getId=(ele)=>{
  debouncedClickHandler(ele);
 }
  return (
    <Container className=' pt-5 mt-5' >
    <Alert color='success' className={alert}>Your work saved successfully!</Alert>
    <Alert color='danger' id='timeLimit' style={{display:'none'}}>Time limit exceed!</Alert>
    <Card className='mt-5 w-100  mx-auto'>
                <CardBody>
                  <CardTitle className="mb-4">Add Project!</CardTitle>
                  <Form onSubmit={(e)=>e.preventDefault()}>
                  <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Service</Label>
                          <div className="d-flex d-inline">
                          <input className="form-control" value={serviceName} onChange={(e)=>setServiceName(e.target.value)} />
                          <i className='bx bx-plus mt-2' onClick={()=>{addOptions('serviceName',serviceName.toUpperCase())}} style={{fontSize:'20px'}}></i>
                      </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Project</Label>
                          <div className="d-flex d-inline">
                          <input className="form-control" value={projectName} onChange={(e)=>setProjectName(e.target.value)} />
                          <i className='bx bx-plus mt-2'onClick={()=>{addOptions('projectName',projectName.toUpperCase())}}  style={{fontSize:'20px'}}></i>
                      </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Cost Center</Label>
                          <div className="d-flex d-inline">
                          <input className="form-control" value={costCenter} onChange={(e)=>{
                            setCostCenter(e.target.value)
                          } } />
                          <i className='bx bx-plus mt-2' onClick={()=>{addCostCenter()}} style={{fontSize:'20px'}}></i>
                      </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Work Item</Label>
                          <div className="d-flex d-inline">
                          <input className="form-control" value={workItem} onChange={(e)=>setWorkItem(e.target.value)} />
                          <i className='bx bx-plus mt-2' style={{fontSize:'20px'}}></i>
                      </div>
                        </div>
                      </Col>
                    </Row>
                  <Row>
                  <Col md={6}>
                        <div className="mb-3" id="hrsLimit" style={{display:'none'}}>
                          <Label htmlFor="formrow-email-Input">Time Limit</Label>
                          <div className="d-flex d-inline">
                          <input className="form-control" value={hrs} onChange={(e)=>setHrs(e.target.value)} />
                          <i className='bx bx-plus mt-2' onClick={()=>{addTotHrs(costCenter.toUpperCase(),hrs)}} style={{fontSize:'20px'}}></i>
                      </div>
                        </div>
                      </Col>
                  </Row>
                </Form>
                </CardBody>
              </Card>
              </Container>
  )
}

export default FormPage