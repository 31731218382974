import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { CardBody, Card, Row, Col } from "reactstrap";
import { useStateContext } from "Context/ContextProvider";
import {totHoursPerMonth}  from "./totHoursPerMonth";
import { totHours } from "pages/timesheet/Dashboard-saas/Components/totHours";
import { Timestamp, getDocs, collection, getDoc, doc, query,where, orderBy } from "firebase/firestore";
import { db } from "firebase-config";
import { monthData } from "common/data";
import { months } from "pages/timesheet/Dashboard-saas/utils/data";
const EfficiencyChart = ({dataColors,userId}) => {
  const [firstDateTimestamp,setFirstDateTimestamp]=useState('')
  const [lastDateTimestamp,setLastDateTimestamp] = useState('')
  const [name,setName] = useState("")
  const {project,performanceArray,format,setFormat, setPerformanceArray,startdate, enddate,}=useStateContext()
  const startDate = new Date(startdate)
  const endDate = new Date(enddate)
  const [data,setData]= useState([])
  const doughnutEChartColors = getChartColorsArray(dataColors);
  let chartData=[]
  const noDataLabel=[]
      for(var i=0;i<performanceArray.length;i++){
        if(performanceArray[i]===0){
    noDataLabel.push(i)
        }else{
          chartData.push(performanceArray[i])
        }
      }
  
  useEffect(() => {
    const getReport = async () => {
      if (userId !== '') {
        const docRef = doc(db, 'users', userId)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setName(docSnap.data().name)
          const filteredQuery = query(collection(db, 'Timesheet'), where('name', '==', docSnap.data().name), where('dateTimestamp', '>=', firstDateTimestamp), where('dateTimestamp', '<=', lastDateTimestamp), orderBy('dateTimestamp', 'desc'));
          const data1 = await getDocs(filteredQuery)
          setData(data1.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
      }
    }
    getReport()
  }, [userId, firstDateTimestamp,startdate])

  const firstDay = new Date(startdate)
    const lastDay = new Date(enddate)
    const lastDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
    const firstDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
    useEffect(()=>{
      if(format==='weekly'){
        setFirstDateTimestamp(Timestamp.fromDate(firstDay)) 
        setLastDateTimestamp(Timestamp.fromDate(lastDay)) 
      }else{
        setLastDateTimestamp (Timestamp.fromDate(lastDate))
        setFirstDateTimestamp (Timestamp.fromDate(firstDate))
      }
    },[format])
  console.log(data)
  const getReport = async () => {
    setPerformanceArray([])
    let arr = []
    if (format === 'weekly') {
      if (project.length > 0 && data.length > 0) {
        for (let i = 0; i < project.length; i++) {
          let workedHours = 0;
          const projectName = project[i].service;
          for (let j = 0; j < data.length; j++) {
            if (projectName === data[j].projectName) {
              workedHours +=parseFloat(totHours(data[j].startTime, data[j].endTime)) 
            }
          }
          arr.push(workedHours)
        }
        setPerformanceArray(arr)
        setData([])
      }
    }
    else {
      if (project.length > 0 && data.length > 0) {
        for (let i = 0; i < project.length; i++) {
          let workedHours = 0;
          const projectName = project[i].service;
          for (let j = 0; j < data.length; j++) {
            if (projectName === data[j].projectName) {
              workedHours += parseFloat(totHours(data[j].startTime, data[j].endTime))
            }
          }
          arr.push(workedHours)
        }
        setPerformanceArray(arr)
        setData([])
      }
    }
  }
let projectName=[]
    project.map((data,index)=>{
      if(!noDataLabel.includes(index))
        projectName.push(data.service)
    })
  var doughnutChartColors =  getChartColorsArray(dataColors); 
  const data1 = {
    labels: projectName,
    datasets: [
      {
        data: chartData,
        backgroundColor: doughnutChartColors,
        hoverBackgroundColor: doughnutChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }

  return(
    <Card className="h-5 mt-2">
        <CardBody >
          <h6 className="mb-2">{name ? name+"'s Report" : "Choose any one..."}</h6>
          <Row>
            <Col>
            <span className=" mb-3">Total {format === "monthly" ? totHoursPerMonth(startdate,enddate) +" Hours" : "40 Hours" }</span>
            </Col>
            <Col>
            <select  className="form-control" onChange={(e)=>setFormat(e.target.value)}>
            <option value={"#"}>Choose Type...</option>
            <option value={"monthly"}>Month</option>
            <option value={"weekly"}>Week</option>
          </select>
            </Col>
            <Col>
            <select className="form-control">
              <option value={"#"}>Select Month...</option>
            {months.map((data,index)=>(
              <option value={data} key={index}>{data}</option>
            ))}
          </select>
            </Col>
            <Col><button className="btn bg-primary text-white" onClick={()=>getReport()}>Get Report</button></Col>
          </Row>
        
          <Doughnut width={734} height={500} className="chartjs-chart" data={data1} />
        </CardBody>
    </Card>
    
  ) 
}
export default EfficiencyChart;