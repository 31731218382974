import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
} from "reactstrap"
import { db } from 'firebase-config';
import moment from 'moment';
import { Timestamp, addDoc, collection, doc, getDocs, limit, onSnapshot, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'
import Select from '../components/Material/Select';
import { Field, Formik, useFormik } from "formik";
import Cookies from 'js-cookie';
import { functionalModules, groupOptionGroup, initialValues, moduleOptionGroup, priorityOptionGroup, schema, servicceOptionGroup, statusOptionGroup, subModuleOptionGroup, teamOptionsGroup, technicalModules, ticketTemplate, toaster, toasterStyle, typeOptionGroup } from '../utils/data';
import { ToastContainer, toast } from 'react-toastify'
import { DatePicker, TimePicker } from 'antd';
import { useStateContext } from 'Context/ContextProvider';
import InputTag from '../components/Material/InputTag';
import FormFooter from '../components/FormFooter';
import Toaster from '../components/Toaster';
import { orderBy } from 'lodash';
import { generateTicketNo } from '../utils/generateTicketNo';
import DropZone from '../components/DropZone';
import {getDownloadURL,ref,uploadBytes,deleteObject} from 'firebase/storage'
import { storage } from "firebase-config";
const NewTicket = () => {
  const [cc, setCc] = useState('d-none')
  const [selectedFiles, setselectedFiles] = useState([]);
  const { project } = useStateContext()
  const nav = useNavigate()
  const [subModule,setSubModule] = useState(subModuleOptionGroup)
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedGroup1, setselectedGroup1] = useState(null);
  const [selectedGroup2, setselectedGroup2] = useState(null);
  const [selectedGroup3, setselectedGroup3] = useState(null);
  const [selectedGroup6, setselectedGroup6] = useState(null);
  const [selectedGroup7, setselectedGroup7] = useState(null);
  const [selectedGroup8, setselectedGroup8] = useState({id:1 ,label:"Delivery",value:"Delivery"});
  const [selectedGroup9, setselectedGroup9] = useState(null);
  const [selectedGroup10, setselectedGroup10] = useState(null);
  const [selectedGroup11, setselectedGroup11] = useState(null);
  const [consultants, setConsultants] = useState([])
  const [projectOptionGroup, setProjectOptionGroup] = useState([{ options: [] }])
  const [functionalOptionsGroup, setFunctionsGroup] = useState([{ options: [] }])
  const [technicalOptionsGroup, setTechnicalGroup] = useState([{ options: [] }])
  const [inputValue, setInputValue] = useState("")
  const [tickets,setTickets] =useState([])
  
  useEffect(() => {
    const getConsultants = async () => {
      const colRef = query(collection(db, "users"), where("team", '==', "Delivery"))
      const data = await getDocs(colRef).catch((err) => console.log(err))
      setConsultants(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      const collRef = query(collection(db,"Tickets"),orderBy('timestamp','desc'))
      // const ticketData = await getDocs(collRef)
      onSnapshot(collRef,(ticketData)=>{
        setTickets(ticketData.docs.map((doc)=>({...doc.data(),id:doc.id})))
      })
    }
    getConsultants()
  }, [])
  tickets.sort(function (a, b) {
    if (new Date(a.timestamp.seconds) > new Date(b.timestamp.seconds)) return 1;
    if (new Date(a.timestamp.seconds) == new Date(b.timestamp.seconds)) return 0;
    if (new Date(a.timestamp.seconds) < new Date(b.timestamp.seconds)) return -1
  })
  useEffect(() => {
    const getFuncTechConsultants = () => {
      consultants.map((data, index) => {
        functionalModules.map((mod) => {
          if (data.designation.toUpperCase().includes(mod.toLocaleUpperCase())) functionalOptionsGroup[0].options.push({ label: data.name, value: data.email, id: index,key:index })
        })
        technicalModules.map((mod) => {
          if (data.designation.toUpperCase().includes(mod.toLocaleUpperCase())) technicalOptionsGroup[0].options.push({ label: data.name, value: data.email, id: index,key:index })
        })
      })
      setFunctionsGroup(functionalOptionsGroup)
      setTechnicalGroup(technicalOptionsGroup)
    }
    getFuncTechConsultants()
  }, [consultants])

  useEffect(() => {
    const getData = () => {
      project.sort(function (a, b) {
        if (a.service > b.service) return 1;
        if (a.service == b.service) return 0;
        if (a.service < b.service) return -1
      })
      project.map((data, index) => {
        projectOptionGroup[0].options.push({ label: data.service, value: data.service, id: index })
      })
      setProjectOptionGroup(projectOptionGroup)
    }
    getData()
  }, [project])

  const { values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      values.timestamp=Timestamp.now()
      values.customNo = generateTicketNo(tickets[tickets.length-1].customNo) 
      await addDoc(collection(db, 'Tickets'), values).then(async(doc) => {
        const fileRef=ref(storage,'tickets/'+doc.id);
     await uploadBytes(fileRef,selectedFiles[0]).then(()=>{
      toast.success('Ticket raised successfully !', toasterStyle)
    }).catch((err)=>{
      console.log(err);
    })
      }).catch(err => console.log(err))
    }
  })
  const handleSelectGroup = (selectedGroup) => {
    setselectedGroup(selectedGroup);
    values.service = selectedGroup.value
  }
  const handleSelectGroup1 = (selectedGroup) => {
    setselectedGroup1(selectedGroup);
    values.project = selectedGroup.value
  }
  const handleSelectGroup2 = (selectedGroup) => {
    setselectedGroup2(selectedGroup);
    values.module = selectedGroup.value
    setSubModule(subModuleOptionGroup.filter((data)=>data.label.includes(selectedGroup.value)))
  }
  const handleSelectGroup3 = (selectedGroup) => {
    setselectedGroup3(selectedGroup);
    values.submodule = selectedGroup.value
  }
  const handleSelectGroup6 = (selectedGroup) => {
    setselectedGroup6(selectedGroup);
    values.type = selectedGroup.value
  }
  const handleSelectGroup7 = (selectedGroup) => {
    setselectedGroup7(selectedGroup);
    values.priority = selectedGroup.value
  }
  const handleSelectGroup8 = (selectedGroup) => {
    setselectedGroup8(selectedGroup);
    values.team = selectedGroup.value
  }
  const handleSelectGroup9 = (selectedGroup) => {
    setselectedGroup9(selectedGroup);
    values.functional = selectedGroup.value
  }
  const handleSelectGroup10 = (selectedGroup) => {
    setselectedGroup10(selectedGroup);
    values.technical = selectedGroup.value
  }
  const handleSelectGroup11 = (selectedGroup) => {
    setselectedGroup11(selectedGroup);
    values.ticketTemplate = selectedGroup.value
  }
  const handleInputChange = (input) => {
    setInputValue(input)
    if (input) {
      const filteredProject = project.filter((data) => data.service.toLowerCase().includes(input.toLowerCase()))
      filteredProject.sort(function (a, b) {
        if (a.service > b.service) return 1;
        if (a.service == b.service) return 0;
        if (a.service < b.service) return -1
      })
      let arr = []
      filteredProject.map((data, index) => {
        arr = [...arr, { label: data.service, value: data.service, id: index }]
      })
      setProjectOptionGroup([{ options: arr }])
    } else {
      setProjectOptionGroup([{ options: [] }])
    }
  }
  return (
    <React.Fragment>
      <Container className='page-content'>
        <Card className='w-100 mx-auto'>
          <CardBody>
            <Form onSubmit={handleSubmit} >
              <CardTitle className="mb-4">Raise new ticket</CardTitle>
              <Row>
                <Col lg={6}>
                  <Select label={"Service"} options={servicceOptionGroup} errors={errors.service} selectedGroup={selectedGroup} handleSelectGroup={handleSelectGroup} />
                </Col>
                <Col lg={'6'} className='mb-3'>
                <Select label={"Project"} placeholder={"Type project "} options={projectOptionGroup} errors={errors.project} input={inputValue} onInputChange={handleInputChange} selectedGroup={selectedGroup1} handleSelectGroup={handleSelectGroup1} />
                </Col>
              </Row>
              <Row>
                <Col lg={'6'} className={` mb-3`}>
                  <Select label={"Module"} options={moduleOptionGroup} errors={errors.module} selectedGroup={selectedGroup2} handleSelectGroup={handleSelectGroup2} />
                </Col>
                <Col lg={'6'} className={` mb-3`}>
                  <Select label={"Sub-module"} options={subModule} errors={errors.submodule} selectedGroup={selectedGroup3} handleSelectGroup={handleSelectGroup3} />
                </Col>
              </Row>
              <Row>
                <Col lg={'3'} className='mb-3'>
                  <InputTag type={"text"} placeholder={"Please enter title..."} handleChange={handleChange} handleBlur={handleBlur} value={values.ticketTitle} label={"Ticket title"} name={"ticketTitle"} errors={errors.ticketTitle} />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <InputTag type={"text"} placeholder={"Please enter the reporter..."}  handleBlur={handleBlur} value={values.reportedBy=Cookies.get("name")} label={"Reported by"} name={'reportedBy'} errors={errors.reportedBy} />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <InputTag type={"text"} placeholder={"Please enter secondary..."} handleChange={handleChange} handleBlur={handleBlur} value={values.secondaryReported} label={"Secondary Reported by"} name={'secondaryReported'} errors={errors.secondaryReported} />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <InputTag type={"text"} placeholder={'Enter ticket number...'} handleChange={handleChange} handleBlur={handleBlur} value={values.ticketNo} label={"Customer Ticket number"} name={'ticketNo'} errors={errors.ticketNo} />
                </Col>
              </Row>
              <Row>
                <Col lg={'3'} className='mb-3'>
                  <Select label={"Type"} options={typeOptionGroup} errors={errors.type} placeholder={"Select the type..."} selectedGroup={selectedGroup6} handleSelectGroup={handleSelectGroup6} />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <Label htmlFor="formrow-email-Input">Status</Label>
                  <Input type='text' value={values.status = "Open"} disabled />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <Select label={"Priority"} options={priorityOptionGroup}  errors={errors.priority} placeholder={"Select the priority..."} selectedGroup={selectedGroup7} handleSelectGroup={handleSelectGroup7} />
                </Col>
                <Col lg={'3'} className='mb-3'>
                  <Label>Ticket raised on</Label>
                  <DatePicker
                    className={errors.ticketDate ? "  border-danger form-control" : "form-control"}
                    name='ticketDate'
                    placeholder='Please select date...'
                    style={{ fontFamily: "inherit" }}
                    format='YYYY-MM-DD'
                    onBlur={handleBlur}
                    onChange={(date, string) => values.ticketDate = string}
                    disabledDate={(current) => {
                      return current && !current.isSame(moment(), 'day')
                    }} />
                  {errors.ticketDate && <small className="text-danger m-0">{errors.ticketDate}</small>}
                </Col>
              </Row>
              <Row>
                <Col lg={'4'} className='mb-3'>
                  <Label>Assigned to</Label>
                  <Input type='text' value={values.team = "Delivery"} disabled />
                </Col>
                <Col lg={'4'} className='mb-3'>
                  <Select label={"Functional Consultant"} options={functionalOptionsGroup} errors={errors.functional} placeholder={"Select the functional consultant..."} selectedGroup={selectedGroup9} handleSelectGroup={handleSelectGroup9} />
                </Col>
                <Col lg={'4'} className='mb-3'>
                  <Select label={"Technical Consultant"} options={technicalOptionsGroup} errors={errors.technical} placeholder={"Select technical consultant..."} selectedGroup={selectedGroup10} handleSelectGroup={handleSelectGroup10} />
                </Col>
              </Row>
              <Row>
                <Col lg={'12'} className='mb-3'>
                     <InputTag type={"text"} placeholder="Set estimated hours..." handleChange={handleChange} handleBlur={handleBlur} value={values.estimatedTime} label={"Estimated Hours"} name={'estimatedTime'} errors={errors.estimatedTime} />
                  {errors.estimatedTime && <small className="text-danger m-0">{errors.estimatedTime}</small>}
                </Col>
              </Row>
              <Row>
                <Col lg={'12'} className='mb-3'>
                  <InputTag type={"textarea"} placeholder={'Please enter description...'} handleChange={handleChange} handleBlur={handleBlur} value={values.description} label={"Description"} name={'description'} errors={errors.description} />
                </Col>
              </Row>
              <Row>
                <Col lg={'12'} className='mb-3'>
                  <DropZone selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                </Col>
              </Row>
              <FormFooter />
              <Toaster />
            </Form>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  )
}
export default NewTicket