import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import  { ContextProvider } from "Context/ContextProvider";
import { Provider  } from "react-redux";
import { Provider as ReduxToolKit } from "react-redux";
import reduxToolKitStore from "store/store";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    
  
      <React.Fragment>
        <BrowserRouter>
        <Provider store={store} >
          <ReduxToolKit store={reduxToolKitStore}>
          <ContextProvider>
          <App />
          </ContextProvider>
          </ReduxToolKit>
        </Provider>
        </BrowserRouter>
      </React.Fragment>
      
  
);

serviceWorker.unregister()
