import React from 'react'
import TicketData from './TicketData'
import { Card, CardBody, Col, Row } from 'reactstrap'

const TicketInfo = ({selectedData}) => {
    console.log(selectedData)
  return (
    <Card>
    <CardBody>
        <Row>
            <Col lg={6}>
                <TicketData label={"Service"} data={selectedData.service} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Project"} data={selectedData.project} />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <TicketData label={"Ticket title"} data={selectedData.ticketTitle} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Description"} data={selectedData.description} />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <TicketData label={"Ticket Number"} data={selectedData.customNo} />
            </Col>
            <Col lg={6}>
            <TicketData label={"Close date"} data={selectedData.closeDate.split("-").reverse().join("-")} />
                
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <TicketData label={"Team"} data={selectedData.team} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Type"} data={selectedData.type} />
            </Col>
        </Row>
        <Row>
            <Col lg={6} className='d-flex flex-row gap-2 '>
                <TicketData label={"Status"} data={selectedData.status} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Priority"} data={selectedData.priority} />
            </Col>
        </Row>
       
        <Row>
            <Col lg={6}>
            <TicketData label={"Actual Hours"} data={selectedData.actualTime} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Estimated Time"} data={selectedData.estimatedTime} />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <TicketData label={"Module"} data={selectedData.module} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Sub-module"} data={selectedData.submodule} />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <TicketData label={"Reported by"} data={selectedData.reportedBy} />
            </Col>
            <Col lg={6}>
                <TicketData label={"Secondary reported by"} data={selectedData.secondaryReported} />
            </Col>
        </Row>
    </CardBody>
</Card>
  )
}

export default TicketInfo