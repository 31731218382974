import React from 'react'
import { useNavigate } from 'react-router-dom'

const FormFooter = () => {
    const nav= useNavigate()
  return (
    <div className='d-flex float-start gap-2'>
                        <button type="submit" className=' btn bg-primary text-white'>
                          Raise Ticket
                        </button>
                        <button type="button" className=' btn bg-light text-dark' onClick={() => nav('/ticket/dashboard')}>
                          Cancel
                        </button>
                      </div>
  )
}

export default FormFooter