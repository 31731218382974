import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

//import components
// import { JobWidgetCharts } from './JobCharts';

const ChartSection = ({ chartsData }) => {
    return (
        <React.Fragment>
            <Row className='d-inline-flex flex-wrap overflow-auto w-100 '>
                {(chartsData || []).map((item, key) => (
                    <Col lg={2} key={key}>
                        <Card className="mini-stats-wid">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">{item.title}</p>
                                        <h4 className="mb-0">{item.price}</h4>
                                    </div>
                                    {/* <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-primary-subtle rounded-circle text-primary font-size-20">
                        <i className={item.icon}></i>
                      </div>
                    </div> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
}

export default ChartSection;