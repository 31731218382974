import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

const Toaster = () => {
  return (
    <ToastContainer
    position="bottom-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  // transition:Bounce
  />
  )
}

export default Toaster