import React, { useEffect, useState } from 'react'
import { Col, Card, CardBody, Input,Label,Row,CardTitle, DropdownMenu,DropdownToggle,UncontrolledDropdown,Button } from "reactstrap";
import ReactSelect from "react-select"
import TicketData from './TicketData';
import { priorityColor, statusColor, statusOptionGroup } from '../utils/data';
import { useNavigate } from 'react-router-dom';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from 'firebase-config';
import RatingModal from 'pages/Modal/RatingModal';

const TicketCard = ({data}) => {
    const nav = useNavigate()
    const [bg,setBg]= useState("")
    const [priorityBgColor,setProirityBgColor] = useState("")
    const [rating, setRating] = useState(0)
    const [open , setOpen] = useState(false)
    const toggle=()=>{
    setOpen(!open)
  }
    const handleUpdate=async(status)=>{
     if(status!=="Closed") await updateDoc(doc(db,"Tickets",data.id),{status:status}).catch(err=>console.log(err))
     else await updateDoc(doc(db,"Tickets",data.id),{status:status,rating:rating}).then(()=>toggle()).catch(err=>console.log(err))
  }
    useEffect(()=>{
      const handleBgColor=()=>{
        if(data){
         statusColor.map((obj)=>{
          if(data.status===obj.status) setBg(obj.color)
         })
      priorityColor.map((obj)=>{
        if(data.priority===obj.priority) setProirityBgColor(obj.color)
      })
        }
      }
      handleBgColor()
    },[data])
    
  return (
    <React.Fragment>
    <RatingModal open={open} toggle={toggle} handleUpdate={handleUpdate} setRating={setRating} />
<Row className='d-flex flex-row  align-items-center justify-content-center bg-white'>
                        <Col sm={3}>
                          <UncontrolledDropdown >
                            <DropdownToggle className='text-dark'
                        tag="a"
                        color="white">
                             <div className={bg+' text-white text-center p-2 d-flex align-items-center justify-content-center gap-3'}>
                          {data.status} <i className='bx bx-chevron-down' />
                          </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              {
                                statusColor.map((obj,index)=>(
                                  obj.status==="Closed" ?(<Button key={index} className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>setOpen(true)}>
                                  <div className={'avatar-title rounded-circle '+obj.color} style={{height:'10px',width:'10px'}}></div>
                                         {obj.status}
                                      </Button>):
                                  <Button key={index} className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate(obj.status)}>
                                <div className={'avatar-title rounded-circle '+obj.color} style={{height:'10px',width:'10px'}}></div>
                                       {obj.status}
                                    </Button>
                                ))
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                         
                        </Col>
                        <Col sm={7}>
                        <div className='  d-flex flex-row align-items-center jsutify-content-center gap-2 p-2 '>
                        <span>{data.ticketTitle}</span>
                        &middot;
                          <span>{data.description}</span>  
                          </div>
                        </Col>
                        <Col sm={2}>
                          <div className={priorityBgColor+' text-white text-center p-2'}>
                          {data.priority} 
                          </div>
                        </Col>
                      </Row>
                <Card style={{cursor:"pointer"}} onClick={()=>nav(`/ticket/viewticket/${data.id}`)}>
                    <CardBody>
                <div className='d-flex flex-row  align-items-center justify-content-evenly' >
                <Col sm={1}>
                <TicketData label={"Ticket No"} data={data.customNo} />
                </Col>
                <Col sm={1}>
                <TicketData label={"Type"} data={data.type} />
                </Col>
                <Col sm={1}>
                <TicketData label={"Module"} data={data.module} />
                </Col>
                <Col lg={2} className='me-2'>
                    <TicketData label={"Functional Consultant"} data={data.functional}/>                    
                </Col>
                <Col lg={2} className='me-2'><TicketData label={"Technical Consultant"} data={data.technical}/> </Col>
                <Col sm={1} className='d-flex flex-row gap-2'>
                  {data.ticketDate &&
                  <TicketData label={"Created on"}  data={data.ticketDate.split("-").reverse().join("-")}/>  
}
                  </Col> 
                </div>
                    </CardBody>
                </Card>         
    </React.Fragment>
  )
}

export default TicketCard