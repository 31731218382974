import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { StatisticsApplicationsChart } from './JobCharts';

const StatisticsApplications = () => {
   
const statistic_data = { companay:  [40,39,56,76,36,91,56,28,90,38,98], newjobs:  [40,39,56,76,36,91,56,28,90,38,98], totaljobs:  [40,39,56,25,36,26,76,56,90,38,45], jobview: [40,39,43,76,36,19,56,89,90,38,65], label: [45,94,78,67,36,14,26,82,78,63,41] }


    return (
        <React.Fragment>
            <Col lg={8}>
                <Card>
                    <CardBody>
                            <h4 className="card-title mb-4">Ticket Statistics</h4>
                        <StatisticsApplicationsChart seriesData={statistic_data} dataColors='[ "--bs-success", "--bs-primary","--bs-info", "--bs-warning"]' dir="ltr" />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StatisticsApplications;