import React from 'react'
import { Label } from 'reactstrap'
import ReactSelect from "react-select"
import { customStyles } from 'pages/Ticket/utils/data'

const Select = ({options,handleSelectGroup,selectedGroup,errors,label,placeholder,input,onInputChange}) => {
  return (
    <>
    <Label>{label}</Label>
                          <ReactSelect styles={customStyles} options={options}
                          onInputChange={onInputChange}
                            className="select2-selection"
                            placeholder={placeholder}
                            onChange={handleSelectGroup}
                            inputValue={input}
                            filterOption={false}
                            value={selectedGroup}
                          />
                          { errors && (
                            <small className='text-danger'>{errors}</small>
                          )}</>
  )
}

export default Select