import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import './indexcss.css';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore'
import { db, auth } from 'firebase-config'
import Cookies from 'js-cookie'
import { useStateContext } from 'Context/ContextProvider';
const Leavecards = () => {
const today=new Date()
const [balance,setBalance]=useState(0)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [casual, setCasual] = useState(0);
    const [lop, setLop] = useState(0);
    const [earned, setEarned] = useState(0);
    const [paternity, setPaternity] = useState(0);
    const [sick, setSick] = useState(0);
    const [flexi, setFlexi] = useState(0);
    const [casualAvail, setCasualAvail] = useState(0);
    const [lopAvailable, setLopAvail] = useState(0);
    const [earnedAvailable, setEarnedAvail] = useState(0);
    const [paternityAvailable, setPaternityAvail] = useState(0);
    const [sickAvailable, setSickAvail] = useState(0);
    const [flexiAvailable, setFlexiAvail] = useState(0);
    const [leaveBalance,setLeaveBalance]=useState([])
    useEffect(() => {

        const handleGet = async () => {
            const docRef = doc(db, "admin", JSON.parse(sessionStorage.getItem('uid')));
            setName(() => Cookies.get('name'))

            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                var data = Cookies.get('name')
                setCasual(docSnap.data().casual)
                setEarned(docSnap.data().earned)
                setPaternity(docSnap.data().paternity)
                setLop(docSnap.data().lop)
                setSick(docSnap.data().sick)
                setFlexi(docSnap.data().flexi)
                setEmail(Cookies.get('email'))
                setLeaveBalance(docSnap.data().leaveBalance)
                if (docSnap.data().casualAvailable <= 0) {
                    setCasualAvail(0)
                }
                else {
                   
                        setCasualAvail(docSnap.data().casualAvailable)
                    }
                
                if (docSnap.data().earnedAvailable <= 0) {
                    setEarnedAvail(0)
                } else {

                    setEarnedAvail(docSnap.data().earnedAvailable)
                    
                }
                if (docSnap.data().paternityAvailable == 0) {
                    setPaternityAvail(0)
                }
                else {
                    setPaternityAvail(docSnap.data().paternityAvailable)
                }
                if (docSnap.data().lopAvailable == 0) {
                    setLopAvail(0)
                } else {
                    setLopAvail(docSnap.data().lopAvailable)
                }
                if (docSnap.data().sickAvailable <= 0) {
                    setSickAvail(0)
                }
                else {
                    if (docSnap.data().sickAvailable == 12) {
                        setSickAvail(12)
                    }
                    else {
                        setSickAvail(docSnap.data().sickAvailable)
                    }
                }
                if(docSnap.data().flexiAvailable<=0){
                    setFlexiAvail(0)
                }
                else{
                    setFlexiAvail(docSnap.data().flexiAvailable)
                }
            }
        }
        handleGet()
    }, []
    )
    if(sickAvailable>6){
        const sickBalance=sickAvailable-6
     setBalance(sickBalance)
     setSickAvail(6)
    }
    const getLopPerYear=(lopArr)=>{
        let sum=0
        lopArr.map((data)=>{
            sum+=data
        })
        return sum
    }

    return (
        <div className="d-flex  cards-box">
       <Card className="leave-cards d-inline me-3">
        <CardBody className="p-4">
            <div className="text-center mb-3 text-primary">
          
                <i className="fas fa-umbrella-beach fa-2x "></i>
                    <h5 className="mt-4 mb-2 font-size-15"><b>Total Leave</b></h5>
            </div>

            <div className="d-flex">
                <p className="mb-0 flex-grow-1 text-success me-5">
                    Available {leaveBalance[new Date().getMonth()]}</p>
                    
                <p className="mb-0 text-danger">Booked  {casual+sick+paternity+earned+flexi+lop}</p>
            </div>
        </CardBody>
    </Card>
    <Card className="leave-cards d-inline me-3 ps-2 pe-2" >
        <CardBody className="p-4">
            <div className="text-center mb-3 text-primary">
           <i className="fas fa-calendar-check fa-2x"></i>
                    <h5 className="mt-4 mb-2 font-size-15"><b>Approved Leave</b></h5>
            </div>
            <div className="d-flex">
                <p className="mb-0 flex-grow-1 text-success me-5">Casual  {casualAvail+balance}</p>
                <p className="mb-0 text-danger">Sick  {sickAvailable}</p>
            </div>
        </CardBody>
    </Card>
        <Card className="leave-cards d-inline me-3">
            <CardBody className="p-4">
                <div className="text-center mb-3 text-primary">
                    <i className="fas fa-wallet fa-2x">
                        </i>
                        <h5 className="mt-4 mb-2 font-size-15"><b>Earned leave</b></h5>
                </div>
    
                <div className="d-flex">
                    <p className="mb-0 flex-grow-1 text-success me-5">Available {earnedAvailable}</p>
                    <p className="mb-0 text-danger">Booked  {earned}</p>
                </div>
            </CardBody>
        </Card>
        <Card className="leave-cards d-inline me-3">
            <CardBody className="p-4">
                <div className="text-center mb-3 text-primary">
                 <i className="mdi mdi-party-popper fa-2x">
                    </i>
                        <h5 className="mt-4 mb-2 font-size-15"><b>Flexi Leave</b></h5>
                </div>
    
                <div className="d-flex">
                    <p className="mb-0 flex-grow-1 text-success me-5">Available  {flexiAvailable}</p>
                    <p className="mb-0 text-danger">Booked  {flexi}</p>
                </div>
            </CardBody>
        </Card>
        <Card className="leave-cards d-inline me-3"  >
            <CardBody className="p-4">
                <div className="text-center mb-3 text-primary">
                <i className='fas fa-exclamation-circle fa-2x'></i>
                        <h5 className="mt-4 mb-2 font-size-15"><b>Loss of pay</b></h5>
                </div>
                <div className="d-flex">
                    <p className="mb-0 flex-grow-1 text-danger me-5">Per Month  {lopAvailable[new Date().getMonth()]}</p>
                    <p className="mb-0 text-danger">Per Year  {lopAvailable ? getLopPerYear(lopAvailable) : 0}</p>
                </div>
                 
            </CardBody>
        </Card>
        </div>
    )
}

export default Leavecards