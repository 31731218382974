
const dataArray=(data)=>{
    var sun,mon,tue,wed,thu,fri,sat
     sun = data.sun
     mon = data.mon
     tue = data.tue
     wed = data.wed
     thu = data.thu
     fri = data.fri
     sat = data.sat
     return {sun,mon,tue,wed,thu,fri,sat}
}
export default dataArray