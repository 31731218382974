import React from 'react'
import { Label, Input } from 'reactstrap'

const InputTag = ({type,name,placeholder,handleChange,handleBlur,value,errors,label}) => {
  return (
    <>
    <Label>{label}</Label>
                          <Input
                            type={type}
                            className={errors ? "  border-danger form-control" : "form-control"}
                            name={name}
                            placeholder={placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={value}
                          />
                          {errors && <small className="text-danger m-0">{errors}</small>}
    </>
  )
}

export default InputTag