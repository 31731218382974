import React, { useState } from 'react'
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import noDataFound from "assets/images/nodatafound.png"
import { updateDoc,doc, deleteDoc } from 'firebase/firestore'
import { db } from 'firebase-config'
import { ToastContainer, toast } from 'react-toastify'
import { toasterStyle } from '../utils/data'
import Cookies from 'js-cookie'
import { Timestamp } from 'firebase/firestore'
const TrNo = ({id,data}) => {
    const [displayInput,setDisplayInput] = useState("d-none")
    const [displayImg, setDisplayImg] = useState('d-block')
    const [trNo,setTrNo] = useState("")
    const [trDes,setDes] = useState("")
    const [activity,setActivity] = useState([...data.activities])
    const handleCheck=async()=>{
        activity.push({activity:`${Cookies.get("name")} added new TR : ${trNo}`,timestamp:Timestamp.now()})
        await updateDoc(doc(db,"Tickets",id),{trNo:[...data.trNo,{trNo,trDes}],activities:activity}).then(()=>toast.success("TR added successfully",toasterStyle)).catch((err)=>console.log(err))
        setDes("")
        setTrNo("")
        setDisplayInput("d-none")
    }
    const handleClear=async(index)=>{
       activity.push({activity:`${Cookies.get("name")} cleared the TR : ${data.trNo[index].trNo}`,timestamp:Timestamp.now()})
        data.trNo.splice(index,1)
        console.log(activity)
        await updateDoc(doc(db,"Tickets",id),{trNo:data.trNo,activities:activity}).then(()=>toast.success("TR updated successfully",toasterStyle)).catch((err)=>console.log(err))
        setDes("")
        setTrNo("")
        setDisplayInput("d-none")
    }
  return (
    <>
    <Card>
        <CardBody className=''>
        <h5 className='mb-2'>Transport Number</h5>
            {
              data.trNo &&  data.trNo.length>0 ? (data.trNo.map((ele,index)=>(
                    (<>
                   
                    <Row className='mt-3 mb-3'>
                        <Col>
                        <span className='text-primary'><b>{ele.trNo}</b></span>
                        </Col>
                        <Col className='d-flex flex-row justify-content-between'>
                        <span>{ele.trDes}</span> 
                        <button className='btn bg-white' type='button' onClick={()=>handleClear(index)}>
                        <i className='bx bx-x me-2 font-size-16'  />
                        </button> 
                        
                        </Col>
                    </Row>
                        </>)
                )
                )) : ( <div className={'d-flex align-items-center justify-content-center flex-column '+displayImg}>
                <img src={noDataFound} height={250} width={300} />
                <button type='button' className='btn bg-primary p-2 text-white ' onClick={()=>{setDisplayInput('d-block') 
            setDisplayImg("d-none")}}>+ Add Transport Number</button>
            </div>
            )
            }
            <div className={displayInput+" mb-2 "}>
                <Row>
                    <Col lg={4}>
                    <Label>TR</Label>
                    <Input className='form-control' placeholder='Please enter TR...' value={trNo} onChange={(e)=>{setTrNo(e.target.value)}} />
                    </Col>
                    <Col lg={4}>
                    <Label>Description</Label>
                    <Input className='form-control' placeholder='Please enter description...' value={trDes} onChange={(e)=>{setDes(e.target.value)}} />
                    </Col>
                    <Col lg={4} className='d-flex align-items-center mt-4 gap-2'>
                    <div className='avatar-title rounded-circle bg-success p-2 font-size-18 ' onClick={()=>handleCheck()} style={{height:'30px',width:'30px',cursor:'pointer'}}>
                        <i className='bx bx-check' />
                    </div>
                    <div className='avatar-title rounded-circle bg-danger p-2 font-size-18 ' style={{height:'30px',width:'30px',cursor:'pointer'}} onClick={()=>setDisplayInput("d-none")}>
                        <i className='bx bx-x' />
                    </div>
                    </Col>
                </Row>
            </div>
            <>{
data.trNo && data.trNo.length>0 && (<span  className=' p-2 mt-3 mb-3' style={{cursor:'pointer'}} onClick={()=>{setDisplayInput('d-block') 
setDisplayImg("d-none")}}>+ Add Transport Number</span>)
            }
                </>
        </CardBody>
    </Card>
    <ToastContainer />
    </>
  )
}

export default TrNo