import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap'

const Navbar = ({tabActive,setTabActive}) => {
  return (
    <Card>
        <CardBody>
        <Nav pills>
                        <NavItem>
                            <NavLink className={tabActive==='Activities'?'active':""} onClick={()=>setTabActive("Activities")}>
                                Activities
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tabActive==='Tasks'?'active':""} onClick={()=>setTabActive("Tasks")}>
                                Tasks
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tabActive==='CTA'?'active':""} onClick={()=>setTabActive("CTA")}>
                                CTA
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tabActive==='TrNo'?'active':""} onClick={()=>setTabActive("TrNo")}>
                                TR Number
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tabActive==='Attachments'?'active':""} onClick={()=>setTabActive("Attachments")}>
                                Attachments
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tabActive==='TicketInfo'?'active':""} onClick={()=>setTabActive("TicketInfo")}>
                               Ticket Info
                            </NavLink>
                        </NavItem>
                    </Nav>
        </CardBody>
    </Card>
  )
}

export default Navbar