import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import noDataFound from "assets/images/nodatafound.png"
import { Badge,DropdownMenu,DropdownToggle,UncontrolledDropdown,Button } from 'reactstrap';
import TaskForm from 'pages/Modal/TaskForm'
import { collection, query, onSnapshot, updateDoc, doc } from 'firebase/firestore'
import { db } from 'firebase-config'
import TicketData from './TicketData'
import { useStateContext } from 'Context/ContextProvider';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import MyTasks from './MyTasks';
const Tasks = ({data}) => {
    const params = useParams()
    const [isOpen,setIsOpen] = useState(false)
    const [tasks,setTasks] = useState([])
    useEffect(()=>{
        const getData=()=>{
            const filteredQuery = query(collection(db, 'Tickets',data.id,"tasks"));
    onSnapshot(filteredQuery, (data) => {
      setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    })
        }
        getData()
    },[])
    const handleUpdate=async(status,id)=>{
        await updateDoc(doc(db,"Tickets",data.id,"tasks",id),{status:status}).catch(err=>console.log(err))
    }
    const myTasks=tasks.filter((data)=>data.assignedTo===Cookies.get("email"))
    console.log(myTasks)
  return (
    <Card>
        <TaskForm isOpen={isOpen} setIsOpen={setIsOpen} data={data} />
        <CardBody>
            {
                tasks.length>0  ? (<>
            <Row>
                <Col>Task</Col>
                <Col>Status</Col>
                <Col>Due date</Col>
                <Col lg={2}>Assigned to</Col>
                <Col>Planned hours</Col>
                <Col lg={2}>Description</Col>
            </Row>
            {
                tasks.map((data,index)=>(
                    <Row className='mt-3 mb-3 text-start' key={index}>
                        <Col >{data.taskTitle}</Col>
                        <Col>{
                            data.assignedTo===Cookies.get("email") ? ( <UncontrolledDropdown>
                                <DropdownToggle  className='text-dark'
                            tag="a"
                            color="white">
                                {data.status ==="Open" ?(<div className='header-item d-flex align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):((data.status ==="In-progress" ? (<div className='header-item d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):(data.status ==="Closed") && (<div className='header-item d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>{data.status}</div>)))}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Open",data.id)}>
                                    <div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>
                                            Open
                                        </Button>
                                        <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("In-progress",data.id)}>
                                         <div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>
                                            In-progress
                                        </Button>
                                        <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Closed",data.id)}>
                                    <div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>
                                            Closed
                                        </Button>
                                </DropdownMenu>
                            </UncontrolledDropdown>):(<div>{data.status ==="Open" ?(<div className='header-item d-flex align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):((data.status ==="In-progress" ? (<div className='header-item d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>{data.status}</div>):(data.status ==="Closed") && (<div className='header-item d-flex  align-items-baseline gap-1'><div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>{data.status}</div>)))}</div>)
                        }
                       
                        </Col>
                        <Col>{data.endDate}</Col>
                        <Col lg={2}>{data.assignedTo}</Col>
                        <Col>{data.hours}</Col>
                        <Col lg={2}>{data.taskDescription}</Col>
                    </Row>
                ))
            }
            <button className='btn  mx-auto' onClick={()=>setIsOpen(true)}>+ Create Task</button>
           </>):((tasks.length<=0 )? (<> <div className='d-flex align-items-center justify-content-center flex-column'>
        <p>No tasks assigned !</p>
        <img src={noDataFound} height={250} width={280} />
        <button className='btn bg-primary text-white' type='button' onClick={()=>setIsOpen(true)}>Create Tasks</button>
        </div></>
        ):(
        <MyTasks myTasks={myTasks} />
    ))
            }    
       
        </CardBody>
    </Card>
  )
}

export default Tasks