export const generateTicketNo=(prevNo)=>{
    if(prevNo.includes("TK")){
        const num = prevNo.split("-")
        const ticketNumber = parseInt(num[1])+1
        const result = "SSTTK-0"+ticketNumber
        return result
    }else{
        const result = "TK-01" 
        return result
    }
}