import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  Row,
  Modal,
  Col,
} from "reactstrap";
import Cookies from 'js-cookie';
import TableContainer from "components/Common/TableContainer";
import EfficiencyChart from 'pages/AllCharts/echart/EfficiencyChart';
import { collection, getDocs, query, where, orderBy, onSnapshot, addDoc, doc, getDoc, Timestamp, deleteDoc,updateDoc,  } from 'firebase/firestore'
import { totHours } from '../Components/totHours';
import { useStateContext } from 'Context/ContextProvider';
import { EmployeeName, EmplyeeID, Team, Designation, Actions } from './TeamEfficiencyCol';
import { db } from "firebase-config";
import { workedHrs } from '../Components/workedHrs';
const TeamEfficiency = () => {
  const [varyingModal, setVaryingModal] = useState(false);
  const [userId, setUserId] = useState('')
  const [team, setTeam] = useState([])
  const [costCenter,setCostCenter]=useState([])
  const [colors,setColors]=useState([])
  useEffect(() => {
    const getData = async () => {
      if (Cookies.get('role') === 'Chief Executive Officer'|| Cookies.get('role') === 'Vice President') {
        const filteredUsersQuery = query(collection(db, 'users'), where('team', '==', 'Delivery'));
        const data = await getDocs(filteredUsersQuery).catch((err) => {

        })
        const collectionRef = collection(db,'costCenter')
        const data1 =await getDocs(collectionRef) 
        setCostCenter(data1.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        setTeam(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        const numbers=[0,1,2,3,4,5,6,7,8,9]
            const alphabets = ['A','B','C','D','E'].concat(numbers)
        if(data1.docs.length>0){
          const color=[]
            for(let i=0;i<data1.docs.length;i++){
              let hexCode = '#'
            for(let j=0;j<6;j++){
            hexCode=hexCode+alphabets[Math.floor(Math.random()*11)+1];
            }
            color.push(hexCode)
            
             }
             setColors(color)
        }

      } else {
        const filteredUsersQuery = query(collection(db, 'users'), where('team', '==', Cookies.get('team')));
        const data = await getDocs(filteredUsersQuery).catch((err) => {
        })
        setTeam(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      }
    }
    getData()

  }, [])
 
   

  const newTeam=team.map((data)=>  {
 
    return {
      name:data.name,
      worked : ['0.0','0.0','0.0','0.0','0.0','0.0','0.0','0.0','0.0','0.0','0.0','0.0'],
    }
  }
    )
const handleUpdateCC=async()=>{
    await updateDoc(doc(db,"costCenter","LV63fDbhsLSzuK6kacHB"),{members:newTeam})
}

const columns = useMemo(
  () => [
    {
      Header: "Employee ID",
      accessor: "employeeID",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <EmplyeeID {...cellProps} />;
      },
    },
    {
      Header: "Employee Name",
      accessor: "name",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <EmployeeName {...cellProps} setVaryingModal={setVaryingModal} setUserId={setUserId} team={team} />;
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Actions {...cellProps}  userId={userId} />;
      },
    },
  ],
  [team]
);
  return (

    <div className='page-content'>
      {/* <button onClick={()=>handleUpdateCC()}>update training cc</button> */}

      <Row>
        <Col md={6}>
          
          <h4 className="font-size-16 mb-3">Team Efficiency</h4>
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={team}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                isPagination={true}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="justify-content-center pagination pagination-rounded"
              />
            </CardBody>
          </Card>
        </Col>
        <Col className='mt-4' md={6}>
          <EfficiencyChart dataColors={JSON.stringify(colors)} userId={userId} />
        </Col>
      </Row>

    </div>
  )
}

export default TeamEfficiency