import React from 'react'
import { Card, CardBody } from 'reactstrap'
import noDataFound from "assets/images/nodatafound.png"

const CTA = () => {
  return (
    <Card>
        <CardBody>

        <div className='d-flex align-items-center justify-content-center flex-column'>
        <p>No action taken !</p>
        <img src={noDataFound} height={250} width={280} />
    </div>

        </CardBody>
    </Card>
  )
}

export default CTA