import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Row, NavItem,NavLink,Nav } from "reactstrap";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import HandleTicket from "./TicketCard";
import TicketData from "./TicketData";
import Navbar from "./Navbar";
import EditTicket from "./EditTicket";
import TicketInfo from "./TicketInfo";
import Activities from "./Activities";
import TrNo from "./TrNo";
import CTA from "./CTA";
import Tasks from "./Tasks";
import { useStateContext } from "Context/ContextProvider";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "firebase-config";

const ViewTicketInfo=()=>{
    const params = useParams()
    const [selectedAST,setSelectedAST] = useState({})
    const {tickets,assignedTicket} = useStateContext()
    const selectedData = tickets.filter((data)=>data.id === params.id)
    const getDayDiff=(date)=>{
      var date1_ms = new Date().getTime();
      var date2_ms = new Date(date).getTime();
      var difference_ms = date1_ms-date2_ms ;
      return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
    }
    useEffect(()=>{
      const getSelectedTk=async()=>{
        const docRef = doc(db,"Tickets",params.id)
        onSnapshot(docRef,(docSnap)=>{
          setSelectedAST({...docSnap.data(),id:params.id})
        })
      }
      getSelectedTk()
    },[])
    const [tabActive,setTabActive] = useState("Activities")
    return (
        <React.Fragment>
            <div className="page-content">
            <div className="d-flex align-items-center">
            <i className="mdi mdi-ticket pb-3 ms-2 mt-2 fs-2 pe-2"></i>
            <h4 className="font-size-16">View Ticket</h4>
          </div>
          {
            selectedAST && (
              <>
               <HandleTicket key={params.id} data={selectedAST} />
            
            <Row>
                <Col lg={8}>
                <Navbar tabActive={tabActive} setTabActive={setTabActive} />
                  
                     {
                      tabActive==="TicketInfo" ? (
                      <TicketInfo selectedData={  selectedAST && selectedAST } />):(tabActive==='Attachments'?<EditTicket selectedTicket={ selectedAST && selectedAST} />:(tabActive==="Activities" ? <Activities selectedAST={selectedAST} />:(tabActive==="TrNo"?<TrNo id={params.id} data={  selectedAST && selectedAST } />:tabActive==="CTA" ? <CTA data={ selectedAST && selectedAST} />:tabActive==="Tasks"?<Tasks data={ selectedAST && selectedAST} />:<></>)))
                  }
                  
                </Col>
                <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                      <TicketData label={"Reported on"} data={selectedAST.ticketDate} />
                      </Col>
                      <Col>
                      <TicketData label={"Est closure date"} data={selectedAST.closeDate} />
                      </Col>
                      </Row>
                      <Row>
                      <Col>
                      <TicketData label={"Primary reported by"} data={selectedAST.reportedBy} />
                      </Col>
                      <Col>
                      <TicketData label={"Secondary reported by"} data={selectedAST.secondaryReported} />
                      </Col>
                      <TicketData label={"Overdue by"} data={  getDayDiff(selectedAST.ticketDate)+" days"} />
                      <p className="text-center">{"Ageing > 7 days" }</p>
                      <div className="d-flex justify-content-between">
                          <TicketData label={"SLA Hours"} data={"56 Hrs"} />
                          <TicketData label={"Overdue by"} data={  getDayDiff(selectedAST.ticketDate)*24+" Hrs"} />
                      </div>
                      </Row>
                  </CardBody>
                </Card>
                </Col>
            </Row>
              </>
            )
          }
         
            </div>
          
        </React.Fragment>
    )
}
export default ViewTicketInfo