import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Alert,
} from "reactstrap";
import Select from "react-select";
import AlertModal from "pages/Modal/AlertModal";
import "flatpickr/dist/themes/material_blue.css";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { db, storage } from "firebase-config";
import { collection, addDoc, Timestamp, doc, getDoc, } from "firebase/firestore";
import Cookies from 'js-cookie'
import { ref, uploadBytes } from 'firebase/storage'
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import { useStateContext } from "Context/ContextProvider";
const EditApplication = props => {
  const params = useParams()
  const [data, setData] = useState();
  const [addDetails, setNewDetails] = useState([])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [subject, setSubject] = useState('')
  const [reason, setReason] = useState('')
  const [selectedGroup, setselectedGroup] = useState(null);
  const [session, setSession] = useState('FullDay')
  const [leaveType,setLeaveType]=useState("Sickleave")
  useEffect(() => {
    const getData = async () => {
      const docRef = doc(db, 'leave submssion', params.id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setLeaveType(docSnap.data().leaveType)
        setselectedGroup({label:docSnap.data().leaveType,value:docSnap.data().leaveType})
        setFromDate(docSnap.data().from)
        setToDate(docSnap.data().to)
        setSubject(docSnap.data().subject)
        setReason(docSnap.data().reason)
        setSession(docSnap.data().session)
      }
    }
    getData()
  }, [])
  const [newData, setNewData] = useState(null)
  const [earnedLeave, setEarnedLeave] = useState(0)
  useEffect(() => {
    const getData = async () => {
      const docSnap = await getDoc(doc(db, 'users', JSON.parse(sessionStorage.getItem('uid'))))
      if (docSnap.exists()) {
        setNewData(docSnap.data())
        setEarnedLeave(docSnap.data().earnedAvailable)
      }
    }
    getData()
  }, [])
  
  let details = []
  const today = new Date();
  const team = Cookies.get('team')
  let reportingManager = ''
  
  const nav = useNavigate()
  function getDatesBetweenDates(startDate, endDate) {
    const dates = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date));
    }
    return dates;
  }
  const countDays=(fromDate,toDate)=>{
    const dates = getDatesBetweenDates(fromDate,toDate)
    const holidays = dates.filter(date => (date.getDay()==5 || date.getDay()==6) ) 
return dates.length-holidays.length  
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Col>
              <Card className=' w-100  mx-auto'>
                <CardBody >
                  <CardTitle className="mb-4">View Application!</CardTitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()

                    }
                    }>
                    <Row>
                      <Col md={6}>
                        <div>
                          <Label htmlFor="formrow-email-Input">Leave type</Label>
                          <Select 
                            className="select2-selection"
                            value={selectedGroup}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">No of days</Label>
                          <Input
                            name=""
                            value={countDays(new Date(fromDate),new Date(toDate))}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <Label htmlFor="formrow-email-Input">Start Date</Label>
                          <Input
                            className={"form-control"}
                            id="formrow-email-Input"
                            type="date"
                            readOnly
                            value={fromDate}
                          />
                        </div>

                      </Col>
                      <Col md={6}>
                        <div className="">
                          <Label htmlFor="formrow-password-Input">End Date</Label>
                          <Input
                            className={"form-control"}
                            id="formrow-email-Input"
                            name="toDate"
                            type="date"
                            readOnly
                            value={toDate}
                          />
                        </div>

                      </Col>
                    </Row>
                        <div className="mt-3">
                          <Label htmlFor="formrow-email-Input">Session</Label>
                          <Input className="form-control" value={session} readOnly />
                        </div>
                    <div className="mt-3">
                      {
                        leaveType === 'Sickleave' ? (
                          <>
                            <Label htmlFor="formrow-email-Input">Choose Illness</Label>
                            <Input className="form-control" value={subject} readOnly />
                          </>
                        ) : (
                          <>
                            <Label>Subject</Label>
                            <Input
                              type="text"
                              id="subject"
                              className={"form-control"}
                              name="subject"
                              maxLength="20"
                              placeholder="Please Enter Your Subject..."
                              value={subject}
                              readOnly
                            />
                          </>)
                      }

                    </div>
                    <div className="mt-3">
                      {
                        subject === 'others' && leaveType === 'Sickleave' ? (
                          <>
                            <Label>Explain Your Illness</Label>
                            <Input
                              type="textarea"
                              id="reason"
                              className={"form-control"}
                              name="reason"
                              maxLength="225"
                              rows="3"
                              placeholder="Don't exceed 250 words..."
                              readOnly
                              value={reason}
                            />
                          </>
                        ) : (
                          <>
                            <Label>Reason</Label>
                            <Input
                              type="textarea"
                              id="reason"
                              className={"form-control"}
                              name="reason"
                              maxLength="225"
                              rows="3"
                              placeholder="Don't exceed 250 words..."
                              readOnly
                              value={reason}
                            />
                          </>
                        )
                      }

                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-md mt-5" onClick={()=>nav('/allrecords')}
                      >Back</button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EditApplication;