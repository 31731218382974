export const todoData=[
    {
        id:0,
        subject:"Followup date with customer about Upgrade",
        description:"two factor authentication by google authenticator",
        date:"2024-04-16",
    },
    {
        id:1,
        subject:"Billing Remainder",
        description:"Ticket sharing between groups",
        date:"2024-04-17",
    },
    {
        id:2,
        subject:"E-Invoice Remainder",
        description:"Raising E-Invoice from customer side",
        date:"2024-04-18",
    }
]