import React, { useEffect, useState} from 'react'
import { Card, CardBody } from 'reactstrap'
import noDataFound from "assets/images/nodatafound.png"
import { db } from 'firebase-config'
import {doc, collection, getDoc, getDocs } from "firebase/firestore"
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useStateContext } from 'Context/ContextProvider'

const Activities = ({selectedAST}) => {
  const params = useParams()
  const nav = useNavigate()
  const {tickets,} = useStateContext()
  const selectedTicket = tickets.filter((data)=> data.id === params.id)

  return (
    <Card>
      <CardBody>
      {
        selectedTicket.length>0 ? selectedTicket[0].activities.map((data,index)=>(
          <div className='mt-3 mb-3 d-flex justify-content-between' key={index}>
            <div className='d-flex align-items-center gap-1'><i className='bx bx-user-circle font-size-20' /> {data.activity}</div>
            <div className='text-muted'>{data.timestamp && new Date(data.timestamp.seconds * 1000 + Math.floor(data.timestamp.nanoseconds / 1e6)).toUTCString()}</div>
            </div>
        )):( selectedAST.length>0 ? selectedAST[0].activities.map((data,index)=>(<div className='mt-3 mb-3 d-flex justify-content-between' key={index} >
        <div className='d-flex align-items-center gap-1'><i className='bx bx-user-circle font-size-20' /> {data.activity}</div>
        <div className='text-muted'>{data.timestamp && new Date(data.timestamp.seconds * 1000 + Math.floor(data.timestamp.nanoseconds / 1e6)).toUTCString()}</div>
        </div>)):  (<div className='d-flex align-items-center justify-content-center flex-column'>
        <img src={noDataFound} height={250} width={280} />
        <p>No activities done!</p>
    </div>))
      }
      </CardBody>
     
        
    </Card>
  )
}

export default Activities