import React from 'react'

const TicketData = ({label,data}) => {
  return (
    <div className='d-flex flex-column  '>
        <span className='text-muted mb-1'>{label}</span>
        <div className=' d-flex  align-items-center gap-1' >
        {
                    data==='Open' || data ==="Urgent" || data ==="High" ? (<><div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div> {data}</>):((data==='Pending' ||data === "WaitingForCustomer" || data==="Medium")? (<><div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div> {data}</>) :((data==='Resolved' || data==='Closed' || data==="Low")? (<><div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div> {data}</>):(<><p>{data || "-"}</p></>)))
                  }
                  </div>
        
        </div>

  )
}

export default TicketData