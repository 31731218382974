import * as Yup from "yup";
import Cookies from "js-cookie";
import { Timestamp } from "firebase/firestore";

// statusColor
export  const statusColor=[{status:'Open',color:"bg-primary"},{status:'Customer Action',color:"bg-success"},{status:'Estimation',color:"bg-danger"},{status:'In-progress Functional',color:"bg-warning"},{status:'In-progress Technical',color:"bg-warning"},{status:'Functional APL Approval',color:"bg-primary"},{status:'On-hold',color:"bg-secondary"},{status:'Sent to SAP',color:"bg-dark"},{status:'Waiting for Input',color:"bg-warning"},{status:'Closed',color:"bg-success"}]
// priorityColor
export const priorityColor=[{priority:"Low",color:"bg-success"},{priority:"Medium",color:"bg-warning"},{priority:"High",color:"bg-danger"},{priority:"Urgent",color:"bg-danger"},]

export   const statusOptionGroup = [
    {
      options: [
        { label: "Open", value: "Open", id: 1 },
        { label: "Pending", value: "Pending", id: 2 },
        { label: "Resolved", value: "Resolved", id: 3 },
        // { label: "Closed", value: "Closed", id: 4 },
       { label: "Waiting for customer", value: "WaitingForCustomer", id: 5 },
      ]
    }
  ];
  export   const servicceOptionGroup = [
    {
      options: [
        { label: "AMS", value: "AMS", id: 1 },
        { label: "IMPLEMENTATION", value: "IMPLEMENTATION", id: 2 },
        { label: "T&M", value: "T&M", id: 3 },
        { label: "MINIPROJECT", value: "MINIPROJECT", id: 4 },
       { label: "ROLLOUT", value: "ROLLOUT", id: 5 },
       { label: "MIGIRATION", value: "MIGIRATION", id: 6 },
       { label: "E-INVOICE", value: "E-INVOICE", id: 6 }, 
      ]
    }
  ];
  export const ticketTemplate = [
    {
      options: [
        {label: "Incident Template", value:'Incident Template', id:1},
        {label: "Other Types Template", value:'Other TypesTemplate', id:2},
        {label: "Daily/Weekly Monitoring Template", value:'Daily/Weekly Monitoring Template', id:1},
      ]
    }
  ]
  export   const typeOptionGroup = [
    {
      options: [
        { label: "Monitoring", value: "Monitoring", id: 1 },
        { label: "Incident", value: "Incident", id: 2 },
        { label: "Change request", value: "ChangeRequest", id: 3 },
        { label: "Mini project", value: "Miniproject", id: 4 },
        { label: "SystemRequest", value: "SystemRequest", id: 5 },
        { label: "Development", value: "Development", id: 6 },
        { label: "Enhancement", value: "Enhancement", id: 7 },
        { label: "Training", value: "Training", id: 5 },
        { label: "ServiceRequest", value: "ServiceRequest", id: 8 },
        { label: "Authorization", value: "Authorization", id: 9 },
        { label: "Configuration", value: "Configuration", id: 10 },
        { label: "Data", value: "Data", id: 11 },
        { label: "Others", value: "Others", id: 5 },
      ]
    }
  ];
  export   const priorityOptionGroup = [
    {
      options: [
        { label: "Low", value: "Low", id: 1, color:'#70e000' },
        { label: "Medium", value: "Medium", id: 2,  color:'#ffb703' },
        { label: "High", value: "High", id: 3, color:'#fb8500' },
        { label: "Urgent", value: "Urgent", id: 4, color:'#e63946' },
      ]
    }
  ];

  export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
  }
  export   const groupOptionGroup = [
    {
      options: [
        { label: "Billing", value: "Billing", id: 1 },
        { label: "Customer Support", value: "CustomerSupport", id: 2 },
        { label: "Escalation", value: "Escalation", id: 3 },
      ]
    }
  ];
 export const inboxData = [
    {
        id: 1,
        // avatar: img1,
        title: "Donec vitae sapien ut",
        description: "If several languages coalesce, the grammar of the resulting language",
        author: "Joseph",
        date: "12 Mar, 2020"
    },
    {
        id: 2,
        // avatar: img2,
        title: "Cras ultricies mi eu turpis",
        description: "To an English person, it will seem like simplified English, as a skeptical cambridge",
        author: "Jerry",
        date: "13 Mar, 2020"
    },
    {
        id: 3,
        // avatar: img3,
        title: "Duis arcu tortor suscipit",
        description: "It va esser tam simplic quam occidental in fact, it va esser occidental.",
        author: "Calvin",
        date: "14 Mar, 2020"
    },
    {
        id: 4,
        // avatar: img1,
        title: "Donec vitae sapien ut",
        description: "If several languages coalesce, the grammar of the resulting language",
        author: "Joseph",
        date: "12 Mar, 2020"
    }
]
export const teamOptionsGroup=[
  {
    options:[
      { label: "Delivery", value: "Delivery", id: 1 },
      { label: "Sales", value: "Sales", id: 2 },
      { label: "HR", value: "HR", id: 3 }, 
    ]
  }
]
export   const moduleOptionGroup = [
  {
    options: [
      { label: "MM", value: "MM", id: 1 },
      { label: "FICO", value: "FICO", id: 2 },
      { label: "PP", value: "PP", id: 3 },
      { label: "QM", value: "QM", id: 4 },
      { label: "HCM", value: "HCM", id: 5 },
      { label: "SD", value: "SD", id: 6 },
      { label: "TECHNICAL", value: "TECHNICAL", id: 7 },
      { label: "PS", value: "PS", id: 8 },
      { label: "PM", value: "PM", id: 9 },
      { label: "BW", value: "BW", id: 10 },
    ]
  }
];
export   const subModuleOptionGroup = [
  {
    label:"MM",
    options: [
      { label: "MM-VE", value: "MM-VE", id: 1 },
      { label: "MM-IV", value: "MM-IV", id: 2 },
      { label: "MM-SR", value: "MM-SR", id: 3 },
      { label: "MM-PTP", value: "MM-PTP", id: 4 },
      { label: "MM-FTS", value: "MM-FTS", id: 5 },
    ]
  },
  {
    label:"FICO",
    options: [
      { label: "FICO-GL", value: "FICO-GL", id: 1 },
      { label: "FICO-AR(RECIEVABLE)", value: "FICO-AR", id: 2 },
      { label: "FICO-AR(PAYABLE)", value: "FICO-AR", id: 3 },
      { label: "FICO-BCM", value: "FICO-BCM", id: 4 },
      { label: "FICO-BM", value: "FICO-BM", id: 5 },
      { label: "FICO-WT", value: "FICO-WT", id: 6 },
      { label: "FICO-AA", value: "FICO-AA", id: 7 },
      { label: "FICO-RM", value: "FICO-RM", id: 8 },
      { label: "FICO-TM", value: "FICO-TM", id: 9 },
      { label: "FICO-PC", value: "FICO-PC", id: 10 },
    ]
  },
  {
    label:"PP",
    options: [
      { label: "PP-PP", value: "PP-PP", id: 1 },
      { label: "PP-POP", value: "PP-POP", id: 2 },
      { label: "PP-DM", value: "PP-DM", id: 3 },
      { label: "PP-MRP", value: "PP-MRP", id: 4 },
      { label: "PP-SFC", value: "PP-SFC", id: 5 },
      { label: "PP-CRP", value: "PP-CRP", id: 6 },
      { label: "PP-IS", value: "PP-IS", id: 7 },
    ]
  },
  {
    label:"QM",
    options: [
      { label: "QM-INP", value: "QM-INP", id: 1 },
      { label: "QM-PRP", value: "QM-PRP", id: 2 },
      { label: "QM-FIP", value: "QM-FIP", id: 3 },
      { label: "QM-QUP", value: "QM-QUP", id: 4 },
      { label: "QM-QN", value: "QM-QN", id: 5 },
    ]
  },{
    label:"HCM",
    options: [
      { label: "HCM-OM", value: "HCM-OM", id: 1 },
      { label: "HCM-PA", value: "HCM-PA", id: 2 },
      { label: "HCM-RR", value: "HCM-RR", id: 3 },
      { label: "HCM-MP", value: "HCM-MP", id: 4 },
      { label: "HCM-DD", value: "HCM-DD", id: 5 },
      { label: "HCM-EM", value: "HCM-EM", id: 6 },
      { label: "HCM-TM", value: "HCM-TM", id: 7 },
    ]
  },
  {
    label:"SD",
    options: [
      { label: "SD-SCS", value: "SD-SCS", id: 1 },
      { label: "SD-SHP", value: "SD-SHP", id: 2 },
      { label: "SD-BIL", value: "SD-BIL", id: 3 },
      { label: "SD-CRD", value: "SD-CRD", id: 4 },
      { label: "SD-BOM", value: "SD-BOM", id: 5 },
      { label: "SD-PD", value: "SD-PD", id: 6 },
      { label: "SD-SR", value: "SD-SR", id: 7 },
      { label: "SD-OTC", value: "SD-OTC", id: 8 },
      { label: "SD-BPM", value: "SD-BPM", id: 9 },
      { label: "SD-CPLM", value: "SD-CPLM", id: 10 },
    ]
  },
  {
    label:"TECHNICAL",
    options: [
      { label: "TECHNICAL-FIORI", value: "FIORI", id: 1 },
      { label: "TECHNICAL-WEB-SERVICE", value: "WEB-SERVICE", id: 2 },
      { label: "TECHNICAL-SAP-INTERGRATION", value: "SAP-INTEGRATION", id: 3 },
      { label: "TECHNICAL-SAP-WORKFLOW", value: "SAP-WORKFLOW", id: 4 },
    ]
  },
  {
    label:"PS",
    options: [
      { label: "PS-PP", value: "PS-PP", id: 1 },
      { label: "PS-PM", value: "PS-PM", id: 2 },
      { label: "PS-PC", value: "PS-PC", id: 3 },
      { label: "PS-MBB", value: "PS-MBB", id: 4 },
      { label: "PS-WBS", value: "PS-WBS", id: 5 },
    ]
  },
  {
    label:"PM",
    options: [
      { label: "PM-MP", value: "PM-MP", id: 1 },
      { label: "PM-BM", value: "PM-BM", id: 2 },
      { label: "PM-PM", value: "PM-PM", id: 3 },
    ]
  },
];
export const functionalModules=["PP","PM","FICO","FSCM","MM","SD","QM","IM","PS","HCM","FI"]

export const technicalModules=["ABAP","FIORI","BASIS"]
export const schema = Yup.object().shape({
  service: Yup.string()
    .required('Please enter the service'),
  project: Yup.string()
    .required('Please enter the project'), 
  module: Yup.string()
    .required('Please enter module'),
  submodule: Yup.string()
    .required('Please enter submodule'),
  ticketTitle: Yup.string()
    .required('Please enter ticket name'),
  reportedBy: Yup.string()
    .required('Please enter reporter name'),
  ticketNo: Yup.string()
    .required('Please enter ticket number'),
  ticketDate: Yup.string()
    .required('Please enter ticket create on'),
  team: Yup.string()
    .required('Please enter team'),
    type: Yup.string()
    .required('Please select type'),
  functional: Yup.string()
    .required('Please assign functional consultant'),
  technical: Yup.string()
    .required('Please assign technical counsultant'),
  team: Yup.string()
    .required('Please enter team'),
    estimatedTime: Yup.string()
    .required('Please set estimated closure time'),  
  priority: Yup.string()
    .required('Please enter priority'),
  description: Yup.string().min(3).required("Please enter the ticket description"),
  

});
export const initialValues = {
  name: Cookies.get('name'),
  email: Cookies.get('email'),
  service: "",
  project: "",
  module: "",
  submodule: "",
  ticketTitle: "",
  reportedBy: "",
  secondaryReported: "",
  ticketNo: "",
  type: "",
  status: "",
  priority: "",
  ticketDate: "",
  team: "",
  functional: "",
  technical: "",
  closeDate: "",
  estimatedTime: "",
  ticketTemplate: "",
  description: "",
  trNo:[],
  cta:[],
  customNo:"",
  activities:[{activity:`${Cookies.get("name")} created this ticket`,timestamp:Timestamp.now()}],
  timestamp:""
}
export const toasterStyle= {
  position: "bottom-center",
  autoClose: 5000,
  style: { fontFamily: 'inherit' },
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  // transition:Bounce,
}
