import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ref, listAll , getMetadata, getDownloadURL } from "firebase/storage";
import { storage } from "firebase-config";
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
const EditTicket = () => {
    const params = useParams()
    const nav = useNavigate()
    const [attached,setAttached] = useState([])
    const [fileNames,setFlNames] = useState([])
    const downloadFile=(file)=>{
      const name=file.split(".")
      getDownloadURL(ref(storage, `tickets/${name[0]}`))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'attachment'+name[1]; // Set the filename here
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
  .catch((error) => {
    console.log(error)
  });
    }
useEffect(()=>{
  const getAttch=async()=>{
    const listRef = ref(storage,'tickets/');
    let fileNamesArr=[]
    listAll(listRef)
    .then((result) => {
   setAttached(result.items.map(async (itemRef) => {
        try{
          const metadata = await getMetadata(itemRef)
          const type = metadata.contentType.split("/")
          const fileName = itemRef.name+"."+type[1]
          return fileName
        }
        catch(err) {
          console.log(err)
        }  
    }))
  }).catch((error) => {
      console.log(error)
    });
  }
  getAttch()
},[])
 
useEffect(()=>{
const getFLNames=()=>{
if(attached.length>0){
 Promise.all(attached).then(results=>{
  const fullFilled=results.map(result=>{
    return result
  })
  setFlNames(fullFilled.filter(name=>name.includes(params.id)))
 })
}
  }
  getFLNames()
},[attached])
  return (
    <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle>Find Attachments here</CardTitle>
            <Row>
            {
             fileNames.length>0 && fileNames.map((name,index)=>(
              <Col key={index} className='d-flex flex-row justify-content-between mt-2 mb-2'>
               <div>{name}</div>
               <i className='bx bx-download font-size-16' style={{cursor:'pointer'}} onClick={()=>downloadFile(name)} />
              </Col>
             
             ))
            }
            </Row>
          </CardBody>
        </Card>
    </React.Fragment>
  )
}

export default EditTicket