import Section from 'pages/LeaveTracker/Section';
import React from 'react'
import ChartSection from './components/ChartSection';
import StatisticsApplications from './components/StatisticsApplications';
import CustomerSatisfation from './components/CustomerSatisfation';
import {Row} from 'reactstrap'
import Todo from './components/Todo';
import ToDoModal from 'pages/Modal/ToDoList';
import UnresolvedTickets from './components/UnresolvedTickets';
import { useStateContext } from 'Context/ContextProvider';
const Dashboard = () => {
  const {tickets} = useStateContext()
  const openTickets = tickets.filter((data)=>data.status==="Open")
  const overDue=openTickets.filter((data)=>new Date()>new Date(data.closeDate))
  const onHold = tickets.filter((data)=>data.status==="On-hold")
  const  closed = tickets.filter((data)=>data.status==='Closed')
  const  cta = tickets.filter((data)=>data.status==='Customer Action')
  const inProcessTickets = tickets.filter((data)=> data.status==="In-progress Functional" || data.status==="In-progress Technical")
  const chartsData = [
    {
        id: 1,
        title: "Overdue",
        price: overDue.length,
        iconColor: "primary",
        icon:"bx bx-confused",
        bgColor: "bg-primary-subtle"
    },
    {
        id: 2,
        title: "Open",
        price: openTickets.length,
        iconColor: "primary",
        icon:"mdi mdi-timer-outline",
        bgColor: "bg-primary-subtle"
    },
    {
        id: 3,
        title: "In-process",
        price: inProcessTickets.length,
        iconColor: "primary",
        icon:"mdi mdi-timetable",
        bgColor: "primary-subtle"
    },
    {
        id: 4,
        title: "Onhold",
        price: onHold.length,
        iconColor: "primary",
        icon:"bx bx-lock-open",
        bgColor: "bg-primary-subtle"
    },
    {
      id: 5,
      title: "Customer Action",
      price: cta.length,
      perstangeValue: " 20.63",
      bagdeColor: "danger",
      istrendingArrow: true,
      seriesData: [{
          name: "Total Rejected",
          data: [32, 22, 7, 55, 20, 45, 36, 20],
      }],
      color: '["--bs-danger", "--bs-transparent"]'
  },
  {
    id: 6,
    title: "Closed",
    price: closed.length,
    perstangeValue: " 20.63",
    bagdeColor: "danger",
    istrendingArrow: true,
    seriesData: [{
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
    }],
    color: '["--bs-danger", "--bs-transparent"]'
},
];
  return (
    <React.Fragment>
      <ToDoModal />
      <div className='page-content'>
      <Section btn={'New Ticket'} link={'/ticket/bookticket'} />
      <ChartSection chartsData={chartsData} />
      <Row>
      <StatisticsApplications  />
      <CustomerSatisfation />
      </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard