import { createSlice } from "@reduxjs/toolkit";
import { todoData } from "../utils/todoData";

const initialState={
    isOpen:false,
    todoData:todoData,
}

export const modalSlice= createSlice({
    name:"modal",
    initialState,
    reducers:{
        tog_backdrop:(state)=>{
            state.isOpen=!state.isOpen;
        },
        addTask:(state,action)=>{
            state.todoData.push(action.payload)
        },
        deleteTask:(state,action)=>{
         state.todoData.splice(action.payload,1);
        },
    }
})
export const { tog_backdrop, addTask, deleteTask } = modalSlice.actions;
export default modalSlice.reducer;