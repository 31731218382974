import React from 'react'
import { Card, CardBody, Row, Col, Badge,DropdownMenu,DropdownToggle,UncontrolledDropdown,Button } from 'reactstrap'

const MyTasks = ({myTasks}) => {
    
  return (
   <Card>
    <CardBody>
    <Row>
                <Col>Task</Col>
                <Col>Status</Col>
                <Col>Due date</Col>
                <Col lg={2}>Assigned to</Col>
                <Col>Planned hours</Col>
                <Col lg={2}>Description</Col>
            </Row>
            {
                myTasks.map((data,index)=>(
                    <Row className='mt-3 mb-3 text-start' key={index}>
                        <Col >{data.taskTitle}</Col>
                        <Col>
                        <UncontrolledDropdown>
                            <DropdownToggle  className='text-dark'
                        tag="a"
                        color="white">
                            {data.status}
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Open",data.id)}>
                                <div className='avatar-title rounded-circle bg-success' style={{height:'10px',width:'10px'}}></div>
                                        Open
                                    </Button>
                                    <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("In-progress",data.id)}>
                                     <div className='avatar-title rounded-circle bg-warning' style={{height:'10px',width:'10px'}}></div>
                                        In-progress
                                    </Button>
                                    <Button className='btn dropdown-item d-flex align-items-center gap-1' onClick={()=>handleUpdate("Closed",data.id)}>
                                <div className='avatar-title rounded-circle bg-danger' style={{height:'10px',width:'10px'}}></div>
                                        Closed
                                    </Button>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </Col>
                        <Col>{data.endDate}</Col>
                        <Col lg={2}>{data.assignedTo}</Col>
                        <Col>{data.hours}</Col>
                        <Col lg={2}>{data.taskDescription}</Col>
                    </Row>
                ))
            }
    </CardBody>
   </Card>
  )
}

export default MyTasks